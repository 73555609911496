import React, { ReactElement } from 'react';
import Tag from 'components/pages/base/tagBlock/Tag';
import { TagApiInterface } from 'submodules/api_middleware';
import styles from './TagBlock.module.scss';

interface Props {
    internalTags?: TagApiInterface[];
    tags?: TagApiInterface[];
}

const DEFAULT_COLOR = '#37A8FC';

const TagBlock = ({ internalTags = [], tags = [] }: Props): ReactElement => {
    if (tags.length === 0 && internalTags.length === 0) return null;

    return (
        <div className={styles.root}>
            {[...internalTags, ...tags].map(
                ({ key, value, title, label, link, color, bcgColor }) => (
                    <Tag
                        key={key}
                        value={value}
                        bcgColor={bcgColor || DEFAULT_COLOR}
                        color={color}
                        link={link}
                        label={label}
                        title={title}
                    />
                )
            )}
        </div>
    );
};

export default TagBlock;
