import React, { CSSProperties, VFC } from 'react';
import classNames from 'classnames';
import { SmallMenuItemHeaderSC } from './SmallMenu.styled';
import Icon from 'components/icon/Icon';
import { useTranslation } from 'utils/localization';

interface Props {
    active: boolean;
    text: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    iconStyle: CSSProperties;
}

const SmallMenuItemHeader: VFC<Props> = ({ active, text, icon, iconStyle }) => {
    const { t } = useTranslation();

    return (
        <SmallMenuItemHeaderSC
            className={classNames({
                active,
            })}
        >
            <Icon icon={icon} style={iconStyle} className="icon" />
            <div className="smallMenuText">{t(text)}</div>
        </SmallMenuItemHeaderSC>
    );
};

export default SmallMenuItemHeader;
