import React, { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { getActiveLink } from './helpers';
import { useTranslation } from 'utils/localization';
import AngleDown from 'components/icon/icons/angle_down.svg';
import Icon from 'components/icon/Icon';
import {
    LinkGeneralInterface,
    LinkInterface,
} from 'components/interfaces/GeneralInterface';
import A from 'components/layouts/A';
import { getDynamicPathName } from 'utils/index';
import styles from './SubMenu.module.scss';

interface Props {
    links: LinkGeneralInterface[];
}

const SubMenu = ({ links }: Props): ReactElement => {
    const { t } = useTranslation();
    const router = useRouter();
    const [activeLink, setActiveLink] = useState<LinkInterface>(null);

    useEffect(
        () => setActiveLink(getActiveLink(links, getDynamicPathName(router))),
        [links]
    );

    return (
        <>
            {links.map(({ idx, text, link, blankPage, items }, key) => {
                if (!idx && !link && (!items || items?.length === 0)) {
                    return null;
                }

                if (!items) {
                    items = [];
                }
                const isMainActive = activeLink && activeLink.link === link;
                return (
                    <div
                        className={styles.linksBlock}
                        key={`main-${key} - ${text}`}
                    >
                        <A
                            className={classNames(styles.linkMain, {
                                [styles.noSubLinks]:
                                    key !== 0 && items.length > 0,
                                [styles.active]: isMainActive,
                                [styles.noHover]: !link,
                            })}
                            href={link}
                            blankPage={blankPage}
                        >
                            <>
                                {items.length > 0 && (
                                    <Icon
                                        icon={AngleDown}
                                        className={styles.icon}
                                    />
                                )}
                                {t(text)}
                            </>
                        </A>
                        {items.map(
                            ({ text: innerText, link, blankPage }, index) => {
                                const isItemActive =
                                    activeLink && activeLink.link === link;
                                return (
                                    <A
                                        key={`child-${key}-${index} - ${innerText}`}
                                        // eslint-disable-next-line security/detect-non-literal-fs-filename
                                        className={classNames(styles.link, {
                                            [styles.active]: isItemActive,
                                            [styles.noHover]: !link,
                                        })}
                                        href={link}
                                        blankPage={blankPage}
                                    >
                                        {t(innerText) as string}
                                    </A>
                                );
                            }
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default SubMenu;
