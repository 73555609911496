import React, { ReactElement } from 'react';
import { LinkInterface } from 'components/interfaces/GeneralInterface';
import SubSubMenuWrapper from 'components/subSubMenuWrapper/SubSubMenuWrapper';
import styles from './InnerSubPageMenu.module.scss';

export interface InnerSubPageMenuProps {
    links: LinkInterface[];
}

const InnerSubPageMenu = ({ links }: InnerSubPageMenuProps): ReactElement => {
    if (!links || !links.length || links.length === 1) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <SubSubMenuWrapper links={links} />
        </div>
    );
};

export default InnerSubPageMenu;
