import React, { ReactElement, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import AppLayoutSC from './AppLayout.styled';
import Overview, { OverviewProps } from './overview/Overview';
import stylesDatePicker from 'components/forms/datepicker/DatePickerInner.module.scss';
import Toast from 'components/layouts/Toast';
import Menu from 'components/pages/base/menu/Menu';
import UserMenu from 'components/pages/base/menu/userMenu/UserMenu';
import Head from 'components/layouts/Head';
import Gradient from 'components/icon/Gradient';
import { getGradientColors, shadeColor } from 'utils/index';
import { getDiscipline } from 'utils/helpers/browser/discipline';
import {
    ConfigActionsApiInterface,
    DisciplineEnum,
} from 'submodules/api_middleware';
import TopControlBlock, {
    ActionMapInterface,
} from 'components/pages/base/topControlBlock/TopControlBlock';
import PageTitle, {
    PageTitleProps,
} from 'components/pages/person/detail/base/PageTitle';
import { convertDisciplineToTheme } from 'utils/helpers/browser/theme';
import InnerPageMenu, {
    InnerPageMenuProps,
} from 'components/pages/base/menu/InnerPageMenu';
import { getTopBannerInfo, isSystemDowngraded } from 'utils/helpers/helpers';
import { LinkInterface } from 'components/interfaces/GeneralInterface';
import InnerSubPageMenu from 'components/pages/base/menu/InnerSubPageMenu';
import DisciplineMenu from 'components/pages/base/menu/userMenu/DisciplineMenu';
import Breadcrumbs from 'components/pages/base/breadcrumbs/Breadcrumbs';
import { GlobalContext } from 'pages/_app';
import LogInAs from 'components/pages/base/menu/userMenu/LogInAs';
import LangMenu from 'components/pages/base/menu/userMenu/LangMenu';
import Alert from 'components/login/Alert';

const ARCHIVE_COLORS = ['#878787', '#bbbfbc'];

interface PageTitleInterface extends PageTitleProps {
    topSpace?: boolean;
    child?: ReactElement;
}

interface Props {
    title?: string;
    pageTitle?: PageTitleInterface;
    colors?: string[];
    children: React.ReactChild;
    withBreadcrumbs?: boolean;
    showGradient?: boolean;
    hideUserMenu?: boolean;
    minimizeGradient?: boolean;
    biggestGradient?: boolean;
    hideDisciplineMenu?: boolean;
    showDisciplineMenu?: boolean;
    showLangMenu?: boolean;
    changeDisciplineWithoutReload?: boolean;
    topActionsMap?: ActionMapInterface;
    configActions?: ConfigActionsApiInterface<string, string>;
    innerPageMenu?: InnerPageMenuProps;
    innerSubPageLinks?: LinkInterface[];
    isArchived?: boolean;
    withoutTags?: boolean;
    hideLoginAs?: boolean;
    ignoreDiscipline?: boolean;
    overview?: OverviewProps;
    displayPermanentAlert?: boolean;
}

export enum ThemeEnum {
    'theme_football' = 'theme_football',
    'theme_futsal' = 'theme_futsal',
    'theme_dark' = 'theme_dark',
}

const AppLayoutInner = ({
    title,
    pageTitle,
    children,
    withBreadcrumbs,
    showGradient,
    colors,
    hideUserMenu: hideUserMenuProp,
    minimizeGradient,
    biggestGradient,
    hideDisciplineMenu = false,
    showDisciplineMenu = false,
    showLangMenu = false,
    changeDisciplineWithoutReload,
    topActionsMap,
    configActions,
    innerPageMenu,
    isArchived,
    innerSubPageLinks,
    overview,
    hideLoginAs,
    ignoreDiscipline,
    displayPermanentAlert,
}: Props): ReactElement => {
    const discipline = ignoreDiscipline
        ? DisciplineEnum.football
        : getDiscipline();

    const [theme, setTheme] = useState<ThemeEnum>(
        convertDisciplineToTheme(discipline)
    );
    const { user } = useContext(GlobalContext);

    const hideUserMenu = useMemo(
        () => (user ? !!hideUserMenuProp : true),
        [hideUserMenuProp]
    );

    const topControlElement = (
        <TopControlBlock actions={configActions} actionMap={topActionsMap} />
    );

    const sysDowngrade = isSystemDowngraded();
    const gradientBackground = !!showGradient && (
        <Gradient
            colors={getGradientColors(isArchived ? ARCHIVE_COLORS : colors)}
            topColor={
                isArchived
                    ? shadeColor(ARCHIVE_COLORS[0], -20)
                    : colors && colors.length > 0
                    ? shadeColor(colors[0], -20)
                    : undefined
            }
            className={sysDowngrade ? 'hide' : undefined}
            minimizeGradient={minimizeGradient}
            biggestGradient={biggestGradient}
        />
    );

    const isDetail = !!innerPageMenu;
    return (
        <AppLayoutSC>
            <Toast />
            <Head
                title={title ? title : pageTitle?.primaryBoldText}
                theme={theme}
            />
            <Menu user={user} discipline={discipline} />
            {gradientBackground}
            <div
                className={classNames('content', {
                    ['bannerMargin']: !!getTopBannerInfo(),
                })}
            >
                {(!hideUserMenu || showDisciplineMenu || !hideLoginAs) && (
                    <div
                        className={classNames('userMenu', {
                            ['whiteColor']: showGradient && !sysDowngrade,
                        })}
                    >
                        {!hideUserMenu && <UserMenu user={user} />}
                        {!hideLoginAs && <LogInAs />}
                        {(!hideDisciplineMenu || showDisciplineMenu) &&
                            false && (
                                <DisciplineMenu
                                    discipline={discipline}
                                    setTheme={setTheme}
                                    withoutPageReload={
                                        changeDisciplineWithoutReload
                                    }
                                />
                            )}
                        {showLangMenu && false && <LangMenu />}
                    </div>
                )}
                {withBreadcrumbs && (
                    <div
                        className={classNames('breadcrumbs', {
                            ['indent']: isDetail,
                        })}
                    >
                        <Breadcrumbs
                            onDarkBackground={showGradient && !sysDowngrade}
                        />
                    </div>
                )}
                {pageTitle && !pageTitle.child && (
                    <div
                        className={classNames('flex', {
                            ['detail']: isDetail,
                        })}
                    >
                        <div
                            className={classNames('titleWrapper', {
                                ['topSpace']: pageTitle?.topSpace,
                            })}
                        >
                            {displayPermanentAlert && <Alert />}
                            <PageTitle
                                primaryBoldText={pageTitle.primaryBoldText}
                                secondaryText={pageTitle.secondaryText}
                                text={pageTitle.text}
                                isSmaller={pageTitle.isSmaller}
                                primaryNormalText={pageTitle.primaryNormalText}
                                isDark={pageTitle?.isDark || sysDowngrade}
                                isCentered={pageTitle?.isCentered}
                            />
                        </div>
                        <div className={'actionWrapper'}>
                            {topControlElement}
                        </div>
                    </div>
                )}
                {pageTitle && pageTitle.child && (
                    <div
                        className={classNames('relative', {
                            ['topSpace']: pageTitle?.topSpace,
                            ['detail']: isDetail,
                        })}
                    >
                        {pageTitle.child}
                        <div className={'absoluteControl'}>
                            {topControlElement}
                        </div>
                    </div>
                )}

                {innerPageMenu && (
                    <InnerPageMenu
                        links={innerPageMenu.links}
                        marginTop={innerPageMenu.marginTop}
                        imageReplacementText={
                            innerPageMenu.imageReplacementText
                        }
                        editPhotoCb={innerPageMenu.editPhotoCb}
                        imageUrl={innerPageMenu.imageUrl}
                        acceptTypes={innerPageMenu.acceptTypes}
                        aspect={innerPageMenu.aspect}
                        help={innerPageMenu.help}
                        helpKey={innerPageMenu.helpKey}
                    />
                )}

                <div
                    className={classNames({
                        ['detail']: isDetail,
                    })}
                >
                    {!!overview && (
                        <Overview {...overview} isArchived={isArchived} />
                    )}
                    {innerSubPageLinks && (
                        <InnerSubPageMenu links={innerSubPageLinks} />
                    )}
                    {children}
                </div>
            </div>
            <div id="modal" />
            <div id="loading" />
            <div id="tooltip-popper" />
            <div
                id="datepicker-popper"
                className={classNames(
                    'datepickerPopper',
                    stylesDatePicker.wrapper
                )}
            />
            <div id="contextmenu-popper" className={'contextmenu'} />
        </AppLayoutSC>
    );
};

const AppLayout = (props: Props): ReactElement => {
    return <AppLayoutInner {...props}>{props.children}</AppLayoutInner>;
};

export default AppLayout;
