import React, { CSSProperties, VFC } from 'react';
import classNames from 'classnames';
import { MenuItemInterface } from './Menu';
import { SmallMenuItemsSC } from './SmallMenu.styled';
import { useTranslation } from 'utils/localization';
import A from 'components/layouts/A';
import Icon from 'components/icon/Icon';

interface Props {
    items: MenuItemInterface[];
    mainItemIcon: any;
    mainItemIconStyle?: CSSProperties;
    text: string;
    activeItem?: MenuItemInterface;
}

const SmallMenuItems: VFC<Props> = ({
    items,
    mainItemIcon,
    mainItemIconStyle,
    text,
    activeItem,
}) => {
    const { t } = useTranslation();

    return (
        <SmallMenuItemsSC>
            <div className="menuCategory">
                {mainItemIcon && (
                    <Icon
                        icon={mainItemIcon}
                        style={mainItemIconStyle}
                        className="icon"
                    />
                )}
                {t(text)}
            </div>
            {items.map(
                ({ configKey, text, icon, iconStyle, link }, itemKey) => (
                    <div className="menuItem" key={itemKey}>
                        {icon && (
                            <Icon
                                icon={icon}
                                style={iconStyle}
                                className="icon"
                            />
                        )}
                        <A
                            href={link}
                            className={classNames({
                                active:
                                    activeItem &&
                                    configKey === activeItem.configKey,
                            })}
                        >
                            <>{t(text)}</>
                        </A>
                    </div>
                )
            )}
        </SmallMenuItemsSC>
    );
};

export default SmallMenuItems;
