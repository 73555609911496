import React, { ReactElement } from 'react';
import NextHead from 'next/head';
import { ThemeEnum } from 'components/layouts/AppLayout';
import { getThemeVars } from 'utils/helpers/browser/theme';

interface Props {
    title: string;
    theme?: ThemeEnum;
}

const Head = ({ title, theme }: Props): ReactElement => (
    <NextHead>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="shortcut icon" href="/favicon@2x.png" type="image/x-icon" />
        <link rel="apple-touch-icon" href="/touch-icon-iphone.png" />
        <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/touch-icon-ipad.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/touch-icon-iphone-retina.png"
        />
        <link
            rel="apple-touch-icon"
            sizes="167x167"
            href="/touch-icon-ipad-retina.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {theme && (
            <style>{`:root, ::after, ::before {${getThemeVars(theme)}}`}</style>
        )}
    </NextHead>
);

export default Head;
