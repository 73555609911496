import React, { ReactElement } from 'react';
import Tooltip from 'components/layouts/Tooltip';
import A from 'components/layouts/A';
import styles from './Tag.module.scss';

interface Props {
    value: string;
    title?: string;
    label?: string;
    link?: string;
    bcgColor: string;
    color?: string;
}

const Tag = ({
    value,
    color,
    bcgColor,
    title,
    label,
    link,
}: Props): ReactElement => {
    let element: string | ReactElement = link ? (
        <A href={link}>{value}</A>
    ) : (
        value
    );

    if (title || label) {
        let tooltipLabel = label || '';
        if (title) {
            if (label) {
                tooltipLabel += ` (${title})`;
            } else tooltipLabel += `${title}`;
        }
        element = (
            <Tooltip label={tooltipLabel} pointerCursor={!!link}>
                {element}
            </Tooltip>
        );
    }

    return (
        <div
            style={{
                backgroundColor: bcgColor,
                ...(color ? { color: color } : {}),
            }}
            className={styles.root}
        >
            {element}
        </div>
    );
};

export default Tag;
