import React, { FC } from 'react';
import { FormModalInnerProps } from './TopControlBlock';
import EditForm from 'components/editForm/EditForm';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'utils/localization';

interface Props {
    formModalData: FormModalInnerProps;
    setFormModalData: (data: FormModalInnerProps | null) => void;
}

const TopControlBlockFormModal: FC<Props> = ({
    formModalData,
    setFormModalData,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show={true}
            title={t(formModalData.title)}
            onHide={() => setFormModalData(null)}
        >
            <EditForm
                center
                titleCenter
                fields={formModalData.fields}
                submit={formModalData.cb}
                onSuccess={() => {
                    setFormModalData(null);
                    if (formModalData.onSuccess)
                        return formModalData.onSuccess();
                }}
                withCancelChangesBtn
                cancelButtonTextKey={'general.cancel'}
                cancel={() => setFormModalData(null)}
                centerButtons
            />
        </Modal>
    );
};

export default TopControlBlockFormModal;
