import { getMenuCollapsedServer, getMenuServer } from '../server/menuServer';
import { MenuIdentifierEnum } from '../../../components/pages/base/menu/Menu';
import { getCookie, setCookie } from 'utils/helpers/browser/cookies';

export const MENU_SPLITTER = '|';

export const setMenu = (menu: MenuIdentifierEnum[]): void => {
    setCookie('menu', menu.join(MENU_SPLITTER));
};

export const getMenu = (): MenuIdentifierEnum[] => {
    if (!process.browser) {
        return getMenuServer();
    }

    const menu = getCookie('menu')?.split(
        MENU_SPLITTER
    ) as MenuIdentifierEnum[];
    return menu || Object.values(MenuIdentifierEnum);
};

export const setMenuCollapsed = (value: boolean): void => {
    setCookie('menu_collapsed', `${value}`);
};

export const getMenuCollapsed = (): boolean => {
    if (!process.browser) {
        return getMenuCollapsedServer();
    }
    return getCookie('menu_collapsed') === 'true';
};
