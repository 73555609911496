import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import {
    ColumnColor,
    ColumnValueWeight,
    FieldInterface,
    FieldSize,
    FieldValueType,
    LinkDataInterface,
} from 'components/interfaces/GeneralInterface';
import Icon from 'components/icon/Icon';
import CheckCircle from 'components/icon/icons/check_circle.svg';
import CrossCircle from 'components/icon/icons/cross_circle.svg';
import Info from 'components/icon/icons/info.svg';
import { getLinkColumnData } from 'components/tables/ColumnContent';
import Tooltip from 'components/layouts/Tooltip';
import A from 'components/layouts/A';
import styles from './BasicDetailsInnerBlock.module.scss';

interface Props extends FieldInterface {
    className?: string;
}

export const getField = (
    t: TFunction,
    value: FieldValueType,
    label: string,
    size: FieldSize = FieldSize.P20,
    valueSize: 'M-' | 'M' | 'L' = 'L',
    type: 'email' | 'text' = 'text',
    classNameInner: string = null,
    isEmpty?: boolean,
    color?: ColumnColor,
    link?: string,
    center?: boolean,
    classNameBox: string = null,
    withBorderBottomLine?: boolean,
    labelTooltip?: string,
    valueTooltip?: string
): FieldInterface => ({
    value,
    label: isEmpty ? '' : t(label),
    size,
    valueSize,
    type,
    classNameInner,
    classNameBox,
    color,
    link,
    center,
    withBorderBottomLine,
    labelTooltip,
    valueTooltip,
});

const BasicDetailsInnerBlock = ({
    label,
    value,
    size,
    valueSize,
    type,
    classNameInner,
    className,
    color,
    link,
    center,
    classNameBox,
    withBorderBottomLine,
    labelTooltip,
    valueTooltip,
}: Props): ReactElement => {
    const isBooleanValue = typeof value === 'boolean';

    return (
        <div
            className={classNames(
                styles.root,
                className,
                classNameBox,
                styles['size-' + size],
                {
                    [styles.rootBool]: isBooleanValue,
                    [styles['color-' + color]]: !!color,
                    [styles.center]: center,
                    [styles.borderBottomLine]: withBorderBottomLine,
                }
            )}
        >
            <div
                className={classNames(styles.wrapperLabel, {
                    [styles.booleanCenter]: isBooleanValue,
                })}
            >
                {!!labelTooltip && (
                    <div className={styles.labelTooltip}>
                        <Tooltip
                            label={labelTooltip}
                            direction="right"
                            align="left"
                        >
                            <Icon
                                icon={Info}
                                noStrip
                                className={styles.tooltipIcon}
                            />
                        </Tooltip>
                    </div>
                )}
                {!!label && <label className={styles.label}>{label}</label>}
            </div>
            <div
                className={classNames(
                    styles.value,
                    styles['valueSize-' + valueSize],
                    {
                        [styles.lightFirst]: ColumnValueWeight.LIGHT_FIRST,
                    },
                    classNameInner
                )}
            >
                {(!!valueTooltip && (
                    <Tooltip
                        label={valueTooltip}
                        pointerCursor={!!link}
                        center={center}
                    >
                        <span>{getContent(value, link, type)}</span>
                    </Tooltip>
                )) ||
                    getContent(value, link, type)}
            </div>
        </div>
    );
};

export default BasicDetailsInnerBlock;

const getValue = (v: FieldValueType) =>
    v && (v as LinkDataInterface).linkLabel
        ? getLinkColumnData(v as LinkDataInterface)
        : v;

const getContent = (
    value: FieldValueType,
    link: string,
    type: 'email' | 'text'
) => {
    if (typeof value === 'boolean') {
        const icon = (
            <Icon
                icon={value ? CheckCircle : CrossCircle}
                className={styles.icon}
                noStrip
            />
        );
        return link ? <A href={link}>{icon}</A> : icon;
    } else if (value && typeof value === 'object' && 'icon' in value) {
        const icon = (
            <Icon
                icon={value.icon}
                className={classNames(styles.icon, value.className)}
            />
        );
        return link ? <A href={link}>{icon}</A> : icon;
    } else if (Array.isArray(value)) {
        return (value as Array<any>).map((v: FieldValueType, index: number) => (
            <div key={index}>{getValue(v)}</div>
        ));
    } else if (type === 'email') {
        if (value)
            return (
                <a className={styles.link} href={'mailto:' + value}>
                    {value}
                </a>
            );
        else return <span>-</span>;
    } else if (value) {
        if (link) {
            return (
                <A href={link}>
                    <>{value}</>
                </A>
            );
        }
        return getValue(value);
    }

    return '-';
};
