import { ThemeEnum } from 'components/layouts/AppLayout';
import { DisciplineEnum } from 'submodules/api_middleware';

export const convertDisciplineToTheme = (
    discipline: DisciplineEnum
): ThemeEnum => {
    // @ts-ignore-next-line
    return ThemeEnum[`theme_${discipline}`];
};

export const getThemeVars = (theme: ThemeEnum): string => {
    switch (theme) {
        case ThemeEnum.theme_futsal:
            return converetThemeToString(THEME_FUTSAL);
        case ThemeEnum.theme_dark:
            return converetThemeToString(DARK_THEME);
        case ThemeEnum.theme_football:
        default:
            return converetThemeToString(THEME_FOOTBALL);
    }
};

const converetThemeToString = (obj: { [key: string]: string }): string => {
    const keys: string[] = [];
    Object.keys(obj).forEach((key: string) => {
        keys.push(`--${key}:${obj[key]}`);
    });
    return keys.join(';');
};

const DARK_THEME = {
    colorBackground: '#414142',
};

const THEME_FOOTBALL = {
    colorMain: '#263360',
    colorSecondary: '#3d60d3',
    colorSecondaryHover: '#516fd4',
    colorLinkHover: '#8ca7fd',
    colorBackground: '#eaf0f7',
    colorBackgroundHighlightAlpha30: '#bad0ea4d',
};

const THEME_FUTSAL = {
    colorMain: '#06430f',
    colorSecondary: '#1ab154',
    colorSecondaryHover: '#14a64c',
    colorLinkHover: '#1dda65',
    colorBackground: '#f2f2f2',
    colorBackgroundHighlightAlpha30: '#f2faf5',
};
