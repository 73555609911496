import React, {
    ReactElement,
    useState,
    DetailedHTMLProps,
    useEffect,
    useRef,
} from 'react';
import classNames from 'classnames';
import OverviewBlock from './OverviewBlock';
import OverviewWarningBlock from './OverviewWarningBlock';
import {
    FieldInterface,
    GroupEditFieldInterface,
} from 'components/interfaces/GeneralInterface';
import { TagApiInterface } from 'submodules/api_middleware';
import TagBlock from 'components/pages/base/tagBlock/TagBlock';
import { useWindowChange } from 'utils/customHooks/useWindowChange';
import { CardIconBaseInterface } from 'components/card/CardIcon';
import {
    OnSubmitData,
    OnSuccessData,
} from 'components/interfaces/EditFormInterface';
import styles from './Overview.module.scss';

export enum OverviewBackgroundColor {
    red = 'red',
    darkRed = 'darkRed',
    darkHighlight = 'darkHighlight',
    darkOrange = 'darkOrange',
    green = 'green',
    white = 'white',
}

export enum OverviewSize {
    'S30' = 'S30',
    'S40' = 'S40',
    'S50' = 'S50',
    'S60' = 'S60',
    'S70' = 'S70',
    'S80' = 'S80',
}

interface OverviewActionInterface {
    title?: string;
    fields: GroupEditFieldInterface[];
    submit: OnSubmitData;
    onSuccess: OnSuccessData;
}

export interface OverviewInterface {
    hide?: boolean;
    size?: OverviewSize;
    backgroundColor?: OverviewBackgroundColor;
    fields: FieldInterface[];
    onEdit?: (() => void) | OverviewActionInterface;
    onExport?: CardIconBaseInterface;
    whiteActions?: boolean;
    isEditable?: boolean;
    filterOutUnset?: boolean;
}

export interface WarningInterface {
    display?: boolean;
    title?: string;
    successTitle?: string;
    hideOnSuccessState?: boolean;
    size?: OverviewSize;
    warnings: DetailedHTMLProps<
        React.LiHTMLAttributes<HTMLLIElement>,
        HTMLLIElement
    >[];
}

interface TagsInterface {
    tags?: TagApiInterface[];
    internalTags?: TagApiInterface[];
}

export type OverviewBlockType = OverviewInterface | WarningInterface;

export interface OverviewProps {
    tags: TagsInterface;
    blocks: OverviewBlockType | OverviewBlockType[];
    className?: string;
    classNameInner?: string;
    isArchived?: boolean;
}

interface Props extends OverviewProps {
    isArchived?: boolean;
}

const Overview = ({
    tags,
    blocks,
    className,
    classNameInner,
    isArchived,
}: Props): ReactElement => {
    const [height, setHeight] = useState<number>(0);
    const tagsRef = useRef<HTMLDivElement>(null);
    const overviews = (Array.isArray(blocks) ? blocks : [blocks]).filter(
        (o) => {
            if (o) {
                if ('fields' in o) {
                    return !o.hide;
                } else if ('warnings' in o) {
                    if (
                        !o.display ||
                        (o.hideOnSuccessState && o.warnings.length === 0)
                    ) {
                        return false;
                    }
                }
            }

            return true;
        }
    );

    const setHeightFnc = () => {
        if (tagsRef.current) {
            setHeight(tagsRef.current.offsetHeight);
        }
    };

    useEffect(() => {
        setHeightFnc();
    }, [tagsRef]);

    useWindowChange(() => {
        setHeightFnc();
    });

    const indications =
        (!!tags && (
            <TagBlock internalTags={tags.internalTags} tags={tags.tags} />
        )) ||
        null;

    const content = overviews.map((props, index) => {
        if (!props) props = {} as OverviewBlockType;
        let block = null;
        if ('fields' in props) {
            const {
                isEditable,
                fields,
                filterOutUnset,
                onEdit,
                whiteActions,
                backgroundColor,
                onExport,
            } = props;

            block = (
                <OverviewBlock
                    classNameInner={classNameInner}
                    fields={fields}
                    height={height}
                    internalTags={tags?.internalTags}
                    tags={tags?.tags}
                    isArchived={isArchived}
                    showIndications={index === 0}
                    tagsRef={tagsRef}
                    backgroundColor={backgroundColor}
                    whiteActions={whiteActions}
                    onEdit={onEdit}
                    onExport={onExport}
                    filterOutUnset={filterOutUnset}
                    isEditable={isEditable}
                />
            );
        } else if ('warnings' in props) {
            const {
                warnings,
                successTitle,
                title,
                display,
                hideOnSuccessState,
            } = props;

            block = (
                <OverviewWarningBlock
                    warnings={warnings}
                    successTitle={successTitle}
                    title={title}
                    display={display}
                    hideOnSuccessState={hideOnSuccessState}
                    isArchived={isArchived}
                    height={height}
                />
            );
        }

        return (
            <div
                key={index}
                className={classNames(styles.container, {
                    [styles[props.size]]: !!props.size && overviews.length > 1,
                })}
            >
                {block}
            </div>
        );
    });

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.spaceBetween]: overviews?.length > 1,
                [styles.marginTop]:
                    overviews.length === 0 &&
                    (!tags ||
                        ((!tags?.internalTags ||
                            tags?.internalTags?.length === 0) &&
                            (!tags.tags || tags?.tags?.length === 0))),
            })}
        >
            {overviews.length === 0 && !!tags && indications}
            {content}
        </div>
    );
};

export default Overview;
