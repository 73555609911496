import { TFunction } from 'i18next';
import { getRoutePure } from 'constants/routes';

type EnumObject = {
    [A in string]: { label: string; nonClickable?: boolean };
};

export const getPathToString = (t: TFunction): EnumObject => {
    type OrgUnitRole = '/organisation-units/{0}/{1}';
    return {
        [getRoutePure('/compare/players')]: {
            label: t('pages.comparePlayers'),
        },
        [getRoutePure('/compare/players/{0}')]: {
            label: t('pages.comparePlayersDetail'),
        },
        [getRoutePure('/compare/clubs')]: { label: t('pages.compareClubs') },
        [getRoutePure('/compare/clubs/{0}')]: {
            label: t('pages.comparePlayersDetail'),
        },
        [getRoutePure('/decisions')]: {
            label: t('pages.officialBoard') + ' - ' + t('pages.decisions'),
        },
        [getRoutePure('/decisions/{0}')]: {
            label: t('Decision.decisionDetail'),
        },
        [getRoutePure('/regulations')]: {
            label: t('pages.officialBoard') + ' - ' + t('pages.regulations'),
        },
        [getRoutePure('/regulations/{0}')]: {
            label: t('Regulation.regulationDetail'),
        },
        [getRoutePure('/applications')]: { label: t('pages.applications') },
        [getRoutePure('/applications/{0}')]: { label: t('pages.application') },
        [getRoutePure('/users')]: { label: t('pages.users') },
        [getRoutePure('/competitionrewards')]: {
            label: t('pages.competitionRewards'),
        },
        [getRoutePure('/competitionrewards/{0}')]: {
            label: t('pages.competitionRewardsEdit'),
        },
        [getRoutePure('/competitionrewards/new')]: {
            label: t('pages.competitionRewardsCreation'),
        },
        [getRoutePure('/clubs')]: { label: t('pages.clubs') },
        [getRoutePure('/clubs/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/clubs/{0}/requests')]: { label: t('pages.requests') },
        [getRoutePure('/clubs/{0}/applications')]: {
            label: t('pages.applications'),
        },
        [getRoutePure('/clubs/{0}/accounts/transactions')]: {
            label: t('pages.accountsTransactions'),
        },
        [getRoutePure('/clubs/{0}/accounts/subsidies')]: {
            label: t('pages.accountsSubsidies'),
        },
        [getRoutePure('/clubs/{0}/accounts/issued')]: {
            label: t('pages.personAccountsIssuedInvoices'),
        },
        [getRoutePure('/clubs/{0}/accounts/issued/{1}')]: {
            label: t('pages.accountDocumentDetail'),
        },
        [getRoutePure('/clubs/{0}/management')]: {
            label: t('pages.proceedings'),
        },
        [getRoutePure('/clubs/{0}/licence_management')]: {
            label: t('pages.licenceManagement'),
        },
        [getRoutePure('/clubs/{0}/financial_support')]: {
            label: t('pages.financialSupport'),
        },
        [getRoutePure('/clubs/{0}/players')]: { label: t('pages.players') },
        [getRoutePure('/clubs/{0}/competitions')]: {
            label: t('pages.leagues'),
        },
        [getRoutePure('/clubs/{0}/competitions/{1}/team/{2}/stats')]: {
            label: t('pages.clubStats'),
        },
        [getRoutePure('/clubs/{0}/competitions/{1}/team/{2}/player_autumn')]: {
            label: t('pages.clubStatsTeamPlayerAutumn'),
        },
        [getRoutePure('/clubs/{0}/competitions/{1}/team/{2}/player_spring')]: {
            label: t('pages.clubStatsTeamPlayerSpring'),
        },
        [getRoutePure('/clubs/{0}/matches')]: { label: t('pages.matches') },
        [getRoutePure('/clubs/{0}/pitches')]: { label: t('pages.pitches') },
        [getRoutePure('/clubs/{0}/signatures')]: {
            label: t('pages.signatures'),
        },
        [getRoutePure('/clubs/{0}/persons')]: { label: t('pages.persons') },
        [getRoutePure('/clubs/{0}/bulk_memberships')]: {
            label: t('pages.bulkMemberships'),
        },
        [getRoutePure('/clubs/{0}/bulk_memberships/{1}')]: {
            label: t('pages.bulkMembershipDetail'),
        },
        [getRoutePure('/clubs/{0}/bulk_memberships/create')]: {
            label: t('pages.newBulkMembership'),
        },
        [getRoutePure('/clubs/{0}/transfers')]: {
            label: t('pages.transfers'),
        },
        [getRoutePure('/clubs/{0}/transfers/{1}/declaration/{2}/player/{3}')]: {
            label: t('pages.transfer'),
        },
        [getRoutePure('/club-unions')]: { label: t('pages.clubUnions') },
        [getRoutePure('/matches')]: { label: t('pages.matches') },
        [getRoutePure('/matches/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/matches/{0}/home')]: { label: t('pages.home') },
        [getRoutePure('/matches/{0}/visitor')]: { label: t('pages.visitor') },
        [getRoutePure('/matches/{0}/report')]: {
            label: t('pages.refereeReport'),
        },
        [getRoutePure('/matches/{0}/delegate_report')]: {
            label: t('pages.delegateReport'),
        },
        [getRoutePure('/matches/{0}/rewards')]: { label: t('pages.rewards') },
        [getRoutePure('/matches/{0}/audit-log')]: {
            label: t('pages.auditLog'),
        },
        [getRoutePure('/coaches')]: { label: t('pages.coaches') },
        [getRoutePure('/coaches/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/coaches/{0}/licences')]: { label: t('pages.licences') },
        [getRoutePure('/members')]: { label: t('pages.members') },
        [getRoutePure('/members/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/members/{0}/details')]: {
            label: t('pages.personDetails'),
        },
        [getRoutePure('/members/{0}/billing')]: {
            label: t('pages.personBillingFields'),
        },
        [getRoutePure('/members/{0}/requests')]: { label: t('pages.requests') },
        [getRoutePure('/members/{0}/accounts/transactions')]: {
            label: t('pages.accountsTransactions'),
        },
        [getRoutePure('/members/{0}/accounts/issued')]: {
            label: t('pages.personAccountsIssuedInvoices'),
        },
        [getRoutePure('/members/{0}/accounts/issued/{1}')]: {
            label: t('pages.accountDocumentDetail'),
        },
        [getRoutePure('/members/{0}/accounts/rewards')]: {
            label: t('pages.personAccountsRewardsInvoices'),
        },
        [getRoutePure('/members/{0}/accounts/rewards/{1}')]: {
            label: t('pages.accountDocumentDetail'),
        },
        [getRoutePure('/members/{0}/penalization')]: {
            label: t('pages.penalizations'),
        },
        [getRoutePure('/members/{0}/user_access')]: {
            label: t('pages.userAccess'),
        },
        [getRoutePure('/members/{0}/applications')]: {
            label: t('pages.applications'),
        },
        [getRoutePure('/members/{0}/absences')]: {
            label: t('pages.absences'),
        },
        [getRoutePure('/members/{0}/audit-log')]: {
            label: t('pages.auditLog'),
        },
        [getRoutePure('/players')]: { label: t('pages.players') },
        [getRoutePure('/players/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/players/{0}/contracts')]: {
            label: t('PlayerProfileContracts.title'),
        },
        [getRoutePure('/players/{0}/contracts/create')]: {
            label: t('ProfileContractNew.creationGeneral'),
        },
        [getRoutePure('/players/{0}/matches')]: {
            label: t('PlayerProfile.playerMatches'),
        },
        [getRoutePure('/players/{0}/contracts/{1}')]: {
            label: t('pages.contractDetail'),
        },
        [getRoutePure('/players/{0}/contracts/{1}/edit')]: {
            label: t('ProfileContractNew.editGeneral'),
        },
        [getRoutePure('/referees')]: { label: t('pages.referees') },
        [getRoutePure('/referees/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/referees/{0}/licences')]: {
            label: t('pages.licences'),
        },
        [getRoutePure('/referees/{0}/matches')]: { label: t('pages.matches') },
        [getRoutePure('/leagues')]: { label: t('pages.leagues') },
        [getRoutePure('/leagues/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/leagues/{0}/teams')]: { label: t('pages.teams') },
        [getRoutePure('/leagues/{0}/dates')]: { label: t('pages.dates') },
        [getRoutePure('/leagues/{0}/stats')]: {
            label: t('pages.tables'),
            nonClickable: true,
        },
        [getRoutePure('/leagues/{0}/stats/teams')]: {
            label: t('pages.table_teams'),
        },
        [getRoutePure('/leagues/{0}/stats/players')]: {
            label: t('pages.table_players'),
        },
        [getRoutePure('/leagues/{0}/matches')]: { label: t('pages.matches') },
        [getRoutePure('/leagues/{0}/reports_requests')]: {
            label: t('pages.reports_requests'),
        },
        [getRoutePure('/proceedings-stk')]: {
            label: t('pages.proceedings-stk'),
        },
        [getRoutePure('/proceedings-stk/{0}')]: {
            label: t('pages.proceedingsDetail'),
        },
        [getRoutePure('/proceedings-dk')]: { label: t('pages.proceedings-dk') },
        [getRoutePure('/proceedings-dk/{0}')]: {
            label: t('pages.proceedingsDetail'),
        },
        [getRoutePure('/my/messages')]: { label: t('pages.messages') },
        [getRoutePure('/my/proceedings')]: { label: t('pages.proceedings') },
        [getRoutePure('/my/subsidies')]: { label: t('pages.subsidies') },
        [getRoutePure('/my/messages/{0}')]: { label: t('pages.message') },
        [getRoutePure('/delegates')]: { label: t('pages.delegates') },
        [getRoutePure('/delegates/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/delegates/{0}/licences')]: {
            label: t('pages.licences'),
        },
        [getRoutePure('/delegates/{0}/matches')]: { label: t('pages.matches') },
        [getRoutePure('/organisation-units')]: {
            label: t('pages.organisationUnits'),
        },
        [getRoutePure('/organisation-units/{0}')]: {
            label: t('pages.overview'),
        },
        [getRoutePure('/organisation-units/{0}/accounts/grants')]: {
            label: t('pages.orgUnitAccountsGrantsInvoices'),
        },
        [getRoutePure('/organisation-units/{0}/accounts/subsidies')]: {
            label: t('pages.accountsSubsidies'),
        },
        [getRoutePure('/organisation-units/{0}/accounts/grants/{1}')]: {
            label: t('pages.accountDocumentGrantsDetail'),
        },
        [getRoutePure(
            '/organisation-units/{0}/organisation_unit_admin' as OrgUnitRole
        )]: { label: t('OrganisationUnit.roleEnum.organisation_unit_admin') },
        [getRoutePure(
            '/organisation-units/{0}/referee_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.referee_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/referee_commission/nominations' as OrgUnitRole
        )]: { label: t('pages.nominations') },
        [getRoutePure(
            '/organisation-units/{0}/sport_technical_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.sport_technical_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/disciplinary_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.disciplinary_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/delegate_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.delegate_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/delegate_commission/nominations' as OrgUnitRole
        )]: { label: t('pages.nominations') },
        [getRoutePure(
            '/organisation-units/{0}/audit_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.audit_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/ethical_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.ethical_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/appeal_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.appeal_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/executive_committee' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.executive_committee'),
        },
        [getRoutePure(
            '/organisation-units/{0}/coach_commission' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.coach_commission'),
        },
        [getRoutePure(
            '/organisation-units/{0}/coach_department' as OrgUnitRole
        )]: {
            label: t('OrganisationUnit.roleEnum.coach_department'),
        },
        [getRoutePure('/legal-persons')]: { label: t('pages.legalPersons') },
        [getRoutePure('/legal-persons/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/agents')]: { label: t('pages.agents') },
        [getRoutePure('/agents/{0}')]: { label: t('pages.overview') },
        [getRoutePure('/agents/{0}/contracts')]: {
            label: t('pages.contracts'),
        },
        [getRoutePure('/agents/{0}/contracts/{1}')]: {
            label: t('pages.contractDetail'),
        },
        [getRoutePure('/agents/{0}/contracts/new')]: {
            label: t('pages.contractNew'),
        },
        [getRoutePure('/agents/{0}/insurances')]: {
            label: t('pages.insurances'),
        },
        [getRoutePure('/agents/{0}/insurances/{1}')]: {
            label: t('pages.insuranceDetail'),
        },
        [getRoutePure('/agents/{0}/insurances/new')]: {
            label: t('pages.insuranceNew'),
        },
        [getRoutePure('/subsidies-legal-persons')]: {
            label: t('pages.subsidies'),
        },
    };
};

export enum RefereeLicence {
    'A' = 'A',
    'B' = 'B',
    'C' = 'C',
    'D' = 'D',
    'N' = 'N',
    'M' = 'M',
}

export const DEFAULT_GRADIENT_COLORS = ['#090979', '#077990'];
