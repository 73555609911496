import styled from 'styled-components';

export default styled.div`
    position: relative;
    display: flex;
    min-height: 100vh;

    .content {
        position: relative;
        z-index: 1;
        flex-shrink: 1;
        width: 100%;
        max-width: 120rem;
        margin-right: auto;
        margin-left: auto;
        padding: 4rem 2rem 3rem 5.5rem;
        overflow: hidden;

        @media (min-width: 162.5rem) {
            transform: translateX(-11.5rem);
        }

        @media (max-width: 1620px) {
            padding-left: 4rem;
        }

        @media (max-width: 1290px) {
            padding-left: 2rem;
        }

        @media (max-width: 730px) {
            width: 100%;
            padding-top: 6rem;
        }
    }

    .breadcrumbs {
        position: absolute;
        top: 2rem;
        height: 1.5rem;

        &.indent {
            left: 1.5rem;
            @media (max-width: 920px) {
                left: unset;
            }

            @media (max-width: 730px) {
                top: 1.55rem;
                left: 6rem;
            }
        }
    }

    .text {
        display: block;
        margin-bottom: 2.5rem;
        font-weight: 300;
        font-size: 1.125rem;
    }

    .userMenu {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
        display: flex;
        align-items: center;
    }

    .whiteColor {
        color: #fff;

        @media (max-width: 810px) {
            color: var(--colorMain);

            span {
                border-color: var(--colorMain);
            }
        }

        a {
            border-color: #fff;
        }

        span {
            border-color: #fff;
        }
    }

    .contextmenu {
        position: absolute;
    }

    .datepickerPopper {
        z-index: 1500;
    }

    .flexSpaceBetween {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .flex {
        display: flex;
    }

    .titleWrapper {
        flex-grow: 1;
    }

    .actionWrapper {
        display: flex;
        flex-grow: 0;
        justify-content: flex-end;
    }

    .detail {
        margin-left: 13rem;

        @media (max-width: 920px) {
            margin-left: unset;
        }
    }

    .topSpace {
        padding-top: 3rem;
    }

    .relative {
        position: relative;
    }

    .absoluteControl {
        position: absolute;
        top: 0;
        right: 0;
    }

    .noTags {
        height: 2rem;
    }

    .hide {
        display: none;
    }

    .bannerMargin {
        margin-top: 1rem;
    }
`;
