import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { FieldInterface } from 'components/interfaces/GeneralInterface';
import BasicDetailsInnerBlock from 'components/blocks/BasicDetailsInnerBlock';
import stylesInner from 'components/blocks/BasicDetailsInnerBlock.module.scss';
import styles from 'components/blocks/BasicDetailsBlock.module.scss';

interface Props {
    fields: FieldInterface[];
    filterOutUnset?: boolean;
    classNameInner?: string;
}

const makeBlockFields = ({
    fields = [],
    filterOutUnset,
    classNameInner,
}: Props): ReactElement[] =>
    fields
        .filter(
            (field) =>
                !filterOutUnset ||
                !!field.value ||
                typeof field.value === 'boolean'
        )
        .map((field, key) => {
            if (
                Array.isArray(field.value) &&
                field.value.length > 0 && // @ts-ignore-next-line
                (field.value[0]?.value || // @ts-ignore-next-line
                    field.value[0]?.value === false)
            ) {
                return (
                    <div
                        className={classNames(
                            field.classNameBox,
                            styles.subFieldsWrapper,
                            stylesInner['size-' + field.size]
                        )}
                        key={key}
                    >
                        {
                            // @ts-ignore-next-line
                            (field.value as FieldInterface[]).map((field) => (
                                <BasicDetailsInnerBlock
                                    {...field}
                                    key={field.label}
                                    className={classNameInner}
                                />
                            ))
                        }
                    </div>
                );
            }
            return (
                <BasicDetailsInnerBlock
                    {...field}
                    key={field.label}
                    className={classNameInner}
                />
            );
        });

export default makeBlockFields;
