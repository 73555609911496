import React, {
    CSSProperties,
    ReactElement,
    useContext,
    useMemo,
    useState,
} from 'react';
import classNames from 'classnames';
import MenuSC from './Menu.styled';
import NormalMenu from './NormalMenu';
import SmallMenu from './SmallMenu';
import Settings from 'components/icon/icons/settings.svg';
import Book from 'components/icon/icons/book.svg';
import SearchIcon from 'components/icon/icons/search.svg';
import User from 'components/icon/icons/user.svg';
import Player from 'components/icon/icons/player.svg';
import Coin from 'components/icon/icons/coins.svg';
import { getRoute } from 'constants/routes';
import { UserMe } from 'services/user.service';
import { DisciplineEnum } from 'types/types';
import { disciplineTypeToString } from 'utils/index';
import Tooltip from 'components/layouts/Tooltip';
import { getMenuCollapsed, setMenuCollapsed } from 'utils/helpers/browser/menu';
import { getEnvVal } from 'utils/helpers/helpers';
import Icon from 'components/icon/Icon';
import DoubleChevronIcon from 'components/icon/icons/double_chevron.svg';
import { GlobalContext } from 'pages/_app';
import { MenuApiEnum } from 'components/interfaces/GeneralInterface';
import { toDateWithTime } from 'utils/helpers/datetime';
import EnvironmentBar from 'components/environmentBar/EnvironmentBar';
import { useMenuConfig } from 'components/pages/base/menu/useMenuConfig';

export enum MenuIdentifierEnum {
    my = 'my',
    navigation = 'navigation',
    officialBoard = 'officialBoard',
    admin = 'admin',
    finances = 'finances',
    database = 'database',
    discipline = 'discipline',
    user = 'user',
}

export interface MenuInterface {
    justOnMobile?: boolean;
    text: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    iconStyle?: CSSProperties;
    identifier: MenuIdentifierEnum;
    items: MenuItemInterface[];
}

export interface MenuItemInterface {
    configKey?: MenuApiEnum;
    text: string;
    link?: string;
    title?: string;
    justOnMobile?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    iconStyle?: CSSProperties;
}

interface Props {
    user?: UserMe;
    discipline: DisciplineEnum;
    is1RegistrationUrl?: string;
    is1Url?: string;
}

const getMenuItems = (
    userName: string,
    discipline: DisciplineEnum,
    is1RegistrationUrl?: string,
    is1Url?: string
): MenuInterface[] => [
    {
        justOnMobile: true,
        text: userName,
        icon: User,
        identifier: MenuIdentifierEnum.user,
        items: [
            {
                text: 'userMenu.passwordChange',
                link: getRoute('/user/password'),
            },
            {
                text: 'userMenu.logout',
                link: getRoute('/logout'),
            },
        ],
    },
    {
        justOnMobile: true,
        text: disciplineTypeToString(discipline),
        icon: Player,
        identifier: MenuIdentifierEnum.discipline,
        items: [],
    },
    {
        text: 'pages.my',
        identifier: MenuIdentifierEnum.my,
        icon: User,
        items: [
            {
                configKey: MenuApiEnum['messages-list'],
                text: 'pages.messages',
                link: getRoute('/my/messages'),
            },
            {
                configKey: MenuApiEnum['my-proceedings'],
                text: 'pages.proceedings',
                link: getRoute('/my/proceedings'),
            },
            {
                configKey: MenuApiEnum['my-subsidies-view'],
                text: 'pages.subsidies',
                link: getRoute('/my/subsidies'),
            },
        ],
    },
    {
        text: 'pages.navigation',
        identifier: MenuIdentifierEnum.navigation,
        icon: User,
        items: [
            ...(is1RegistrationUrl
                ? [
                      {
                          configKey: MenuApiEnum['navigation-is1-registration'],
                          text: 'pages.is1-registration',
                          title: 'pages.is1-registrationTitle',
                          link: is1RegistrationUrl,
                      },
                  ]
                : []),
            ...(is1Url
                ? [
                      {
                          configKey: MenuApiEnum['navigation-is1-link'],
                          text: 'pages.is1-link',
                          title: 'pages.is1-linkTitle',
                          link: is1Url,
                      },
                  ]
                : []),
            {
                configKey: MenuApiEnum['navigation-documents'],
                text: 'pages.documents',
                link: getRoute('/navigation/documents'),
            },
            {
                configKey: MenuApiEnum['navigation-anonymous-payment'],
                text: 'pages.anonymousPayment',
                link: getRoute('/anonymous-payment'),
            },
            {
                configKey: MenuApiEnum['register-by-club-admin'],
                text: 'pages.register-by-club-admin',
                link: getRoute('/signup-by-club-admin'),
            },
        ],
    },
    {
        text: 'pages.officialBoard',
        icon: Book,
        identifier: MenuIdentifierEnum.officialBoard,
        items: [
            {
                configKey: MenuApiEnum['decisions-search'],
                text: 'pages.decisions',
                link: getRoute('/decisions'),
            },
            {
                configKey: MenuApiEnum['regulations-search'],
                text: 'pages.regulations',
                link: getRoute('/regulations'),
            },
        ],
    },
    {
        text: 'pages.admin',
        icon: Settings,
        identifier: MenuIdentifierEnum.admin,
        items: [
            {
                configKey: MenuApiEnum['fifa-compare-players'],
                text: 'pages.comparePlayers',
                link: getRoute('/compare/players'),
            },
            {
                configKey: MenuApiEnum['fifa-compare-clubs'],
                text: 'pages.compareClubs',
                link: getRoute('/compare/clubs'),
            },
            {
                configKey: MenuApiEnum['delegation'],
                text: 'pages.delegations',
                link: getRoute('/delegations'),
            },
            {
                configKey: MenuApiEnum['nominations-search'],
                text: 'pages.nominations',
                link: getRoute('/nominations'),
            },
            {
                configKey: MenuApiEnum['applications-search'],
                text: 'pages.applications',
                link: getRoute('/applications'),
            },
            {
                configKey: MenuApiEnum['competition-rewards-search'],
                text: 'pages.competitionRewards',
                link: getRoute('/competitionrewards'),
            },
            {
                configKey: MenuApiEnum['proceedings-stk-search'],
                text: 'pages.proceedings-stk',
                link: getRoute('/proceedings-stk'),
            },
            {
                configKey: MenuApiEnum['proceedings-dk-search'],
                text: 'pages.proceedings-dk',
                link: getRoute('/proceedings-dk'),
            },
            {
                configKey: MenuApiEnum['user-search'],
                text: 'pages.users',
                link: getRoute('/users'),
            },
            {
                configKey: MenuApiEnum['manage-groups-users-search'],
                text: 'pages.groupUsers',
                link: getRoute('/group-users'),
            },
            {
                configKey: MenuApiEnum['subsidies-search'],
                text: 'pages.subsidies',
                link: getRoute('/subsidies'),
            },
            {
                configKey: MenuApiEnum['manage-transactions-templates-search'],
                text: 'pages.transactionsTemplates',
                link: getRoute('/accounts/transactions-templates'),
            },
            {
                configKey: MenuApiEnum['search-audit-log'],
                text: 'pages.auditLog',
                link: getRoute('/audit-log'),
            },
        ],
    },
    {
        text: 'pages.finances',
        icon: Coin,
        identifier: MenuIdentifierEnum.finances,
        items: [
            {
                configKey: MenuApiEnum['memberships-search'],
                text: 'pages.memberships',
                link: getRoute('/memberships'),
            },
            {
                configKey: MenuApiEnum['bulk-memberships-search'],
                text: 'pages.bulkMemberships',
                link: getRoute('/bulk-memberships'),
            },
            {
                configKey: MenuApiEnum['accounts-transactions-search'],
                text: 'pages.collectionAccount',
                link: getRoute('/accounts/transactions'),
            },
            {
                configKey: MenuApiEnum['accounts-rewards-invoices-search'],
                text: 'pages.accountsRewardsInvoices',
                link: getRoute('/accounts/rewards'),
            },
            {
                configKey: MenuApiEnum['accounts-issued-invoices-search'],
                text: 'pages.accountsIssuedInvoices',
                link: getRoute('/accounts/issued'),
            },
            {
                configKey: MenuApiEnum['accounts-grants-documents-search'],
                text: 'pages.accountsGrantsDocuments',
                link: getRoute('/accounts/grants'),
            },
            {
                configKey: MenuApiEnum['accounts-subsidies-documents-search'],
                text: 'pages.accountsSubsidies',
                link: getRoute('/accounts/subsidies'),
            },
            {
                configKey: MenuApiEnum['subsidies-legal-persons-search'],
                text: 'pages.subsidies',
                link: getRoute('/subsidies-legal-persons'),
            },
        ],
    },
    {
        text: 'pages.database',
        icon: SearchIcon,
        iconStyle: { strokeWidth: '2px' },
        identifier: MenuIdentifierEnum.database,
        items: [
            {
                configKey: MenuApiEnum['member-search'],
                text: 'pages.members',
                link: getRoute('/members'),
            },
            {
                configKey: MenuApiEnum['player-search'],
                text: 'pages.players',
                link: getRoute('/players'),
            },
            {
                configKey: MenuApiEnum['player-contracts-search'],
                text: 'pages.playerContracts',
                link: getRoute('/players_contracts'),
            },
            {
                configKey: MenuApiEnum['referee-search'],
                text: 'pages.referees',
                link: getRoute('/referees'),
            },
            {
                configKey: MenuApiEnum['coach-search'],
                text: 'pages.coaches',
                link: getRoute('/coaches'),
            },
            {
                configKey: MenuApiEnum['delegate-search'],
                text: 'pages.delegates',
                link: getRoute('/delegates'),
            },
            {
                configKey: MenuApiEnum['agents-search'],
                text: 'pages.agents',
                link: getRoute('/agents'),
            },
            {
                configKey: MenuApiEnum['matches-search'],
                text: 'pages.matches',
                link: getRoute('/matches'),
            },
            {
                configKey: MenuApiEnum['competitions-search'],
                text: 'pages.leagues',
                link: getRoute('/leagues'),
            },
            {
                configKey: MenuApiEnum['club-search'],
                text: 'pages.clubs',
                link: getRoute('/clubs'),
            },
            {
                configKey: MenuApiEnum['club-union-search'],
                text: 'pages.clubUnions',
                link: getRoute('/club-unions'),
            },
            {
                configKey: MenuApiEnum['legal-persons-search'],
                text: 'pages.legalPersons',
                link: getRoute('/legal-persons'),
            },
            {
                configKey: MenuApiEnum['organisation-units-search'],
                text: 'pages.organisationUnits',
                link: getRoute('/organisation-units'),
            },
        ],
    },
];

const Menu = ({ user, discipline }: Props): ReactElement => {
    const { menu } = useContext(GlobalContext);
    const [isToggled, setToggled] = useState(false);
    const [collapsed, setCollapsed] = useState(getMenuCollapsed());
    const config = useMenuConfig();

    const { NEXT_PUBLIC_VERSION, NEXT_PUBLIC_VERSION_DATE } = getEnvVal();

    const menuItems = useMemo<MenuInterface[]>((): MenuInterface[] => {
        const menuItems: MenuInterface[] = getMenuItems(
            user && user.name,
            discipline,
            config.is1RegistrationUrl,
            config.is1Url
        );
        return menuItems
            .map((menuItem) => {
                if (
                    menuItem === null ||
                    menu === null ||
                    menuItem === undefined ||
                    menu === undefined
                ) {
                    return null;
                }

                const items = menuItem.items.filter(
                    (item) => menu.indexOf(item.configKey) !== -1
                );
                if (items.length === 0) {
                    return null;
                } else {
                    return {
                        ...menuItem,
                        items,
                    };
                }
            })
            .filter((mi) => !!mi);
    }, [user, discipline, menu, config.is1RegistrationUrl, config.is1Url]);

    const toggle = () => setToggled(!isToggled);

    const onMenuCollapseClick = () => {
        const newCollapsedValue = !collapsed;
        setCollapsed(newCollapsedValue);
        setMenuCollapsed(newCollapsedValue);
    };

    const versionBlock = !NEXT_PUBLIC_VERSION ? (
        'IS FAČR'
    ) : (
        <Tooltip
            label={
                NEXT_PUBLIC_VERSION_DATE
                    ? (NEXT_PUBLIC_VERSION_DATE as string).includes('T')
                        ? toDateWithTime(NEXT_PUBLIC_VERSION_DATE)
                        : NEXT_PUBLIC_VERSION_DATE
                    : undefined
            }
            center
        >
            {`IS FAČR ${NEXT_PUBLIC_VERSION}`}
        </Tooltip>
    );

    return menuItems.length ? (
        <MenuSC
            className={classNames('menuWrapper', {
                collapsed,
            })}
        >
            <div
                className={classNames('menu', {
                    isToggled,
                })}
            >
                <div className="burgerIcon" onClick={toggle}>
                    <div />
                    <div />
                    <div />
                </div>
                <EnvironmentBar />
                <div
                    className={classNames('root', {
                        collapsed,
                    })}
                >
                    <div className="cross" onClick={toggle}>
                        <div />
                        <div />
                    </div>
                    <div
                        className={classNames('normalMenuContainer', {
                            show: !collapsed,
                        })}
                    >
                        <NormalMenu menuItems={menuItems} />
                    </div>
                    <div
                        className={classNames('smallMenuContainer', {
                            show: collapsed,
                        })}
                    >
                        <SmallMenu menuItems={menuItems} />
                    </div>
                    <div className="bottomBox">
                        <button
                            className={classNames('collapseBtn', {
                                collapsed,
                            })}
                            onClick={onMenuCollapseClick}
                        >
                            <Icon
                                icon={DoubleChevronIcon}
                                className="collapseIcon"
                            />
                        </button>
                        <div
                            className={classNames('version', {
                                show: !collapsed,
                            })}
                        >
                            {versionBlock}
                        </div>
                    </div>
                </div>
            </div>
        </MenuSC>
    ) : null;
};

export default Menu;
