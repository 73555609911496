import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import ProfileImg from 'components/pages/person/detail/base/ProfileImg';
import SubMenu from 'components/pages/base/menu/SubMenu';
import Card from 'components/card/Card';
import { LinkInterface } from 'components/interfaces/GeneralInterface';
import UploadPhotoModal from 'components/photoUploader/UploadPhotoModal';
import { MessageType, pushMessage } from 'components/layouts/Toast';
import { isSystemDowngraded } from 'utils/helpers/helpers';
import {
    PhotoUploaderAcceptTypesEnum,
    PhotoUploaderAspectEnum,
    PhotoUploaderCropShapeType,
} from 'components/photoUploader/PhotoUploader';
import styles from './InnerPageMenu.module.scss';

export interface InnerPageMenuProps {
    imageUrl?: string;
    imageReplacementText?: string[];
    links: LinkInterface[];
    editPhotoCb?: (photo: string) => Promise<void>;
    marginTop?: boolean;
    aspect?: PhotoUploaderAspectEnum;
    cropShape?: PhotoUploaderCropShapeType;
    acceptTypes?: PhotoUploaderAcceptTypesEnum[];
    help?: string;
    helpKey?: string;
}

const InnerPageMenu = ({
    imageUrl,
    imageReplacementText,
    links,
    editPhotoCb,
    marginTop,
    aspect,
    acceptTypes,
    help,
    helpKey,
    cropShape,
}: InnerPageMenuProps): ReactElement => {
    const [showUploadPhotoModal, setShowUploadPhotoModal] =
        useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const onEditPhotoAction = async (photo: string) => {
        setLoading(true);
        try {
            await editPhotoCb(photo);
        } catch (error) {
            pushMessage('upload_photo_error_message', MessageType.DANGER);
        }

        setLoading(false);
        setShowUploadPhotoModal(false);
    };

    return (
        <>
            {showUploadPhotoModal && (
                <UploadPhotoModal
                    show={true}
                    onHideCb={() => setShowUploadPhotoModal(false)}
                    setValue={onEditPhotoAction}
                    isLoading={isLoading}
                    aspect={aspect}
                    acceptTypes={acceptTypes}
                    help={help}
                    helpKey={helpKey}
                    cropShape={cropShape}
                />
            )}
            <Card
                className={classNames(styles.root, {
                    [styles.marginTop]: !!marginTop,
                    [styles.downgraded]: isSystemDowngraded(),
                })}
                classNameCardIcon={styles.cardIcon}
                action={
                    editPhotoCb
                        ? {
                              type: 'edit',
                              onClick: () => setShowUploadPhotoModal(true),
                              justIcon: true,
                              isWhite: true,
                          }
                        : undefined
                }
            >
                <>
                    <ProfileImg
                        imageUrl={imageUrl}
                        imageReplacementText={imageReplacementText}
                    />
                    <SubMenu links={links} />
                </>
            </Card>
        </>
    );
};

export default InnerPageMenu;
