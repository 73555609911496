import React, { CSSProperties, MutableRefObject, ReactElement } from 'react';
import classNames from 'classnames';
import { NormalMenuItemLinkSC } from './NormalMenu.styled';
import Icon from 'components/icon/Icon';

interface Props {
    text: string;
    title?: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    active: boolean;
    link: string;
    justOnMobile?: boolean;
    forwardRef: MutableRefObject<HTMLDivElement>;
    iconStyle?: CSSProperties;
}

const NormalMenuItem = ({
    text,
    title,
    icon,
    active,
    link,
    justOnMobile,
    forwardRef,
    iconStyle,
}: Props): ReactElement => {
    const content = (
        <span title={title ?? text} ref={forwardRef}>
            <>
                {icon && (
                    <Icon icon={icon} style={iconStyle} className="icon" />
                )}
                {text}
            </>
        </span>
    );
    if (!link) {
        return (
            <span
                className={classNames('root', {
                    justOnMobile,
                    active,
                })}
            >
                {content}
            </span>
        );
    }
    return (
        <NormalMenuItemLinkSC
            className={classNames({
                justOnMobile,
                active,
            })}
            href={link}
            title={title ? title : undefined}
        >
            {content}
        </NormalMenuItemLinkSC>
    );
};

export default NormalMenuItem;
