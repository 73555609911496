import React, { useEffect, useRef, useState, VFC } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import NormalMenuSC from './NormalMenu.styled';
import { getActiveItem } from './helpers';
import NormalMenuItemHeader from './NormalMenuItemHeader';
import NormalMenuItem from './NormalMenuItem';
import { MenuIdentifierEnum, MenuInterface } from './Menu';
import { useTranslation } from 'utils/localization';
import { getMenu, setMenu } from 'utils/helpers/browser/menu';
import Logo from 'components/logo/Logo';
import { getRoute } from 'constants/routes';

interface Props {
    menuItems: MenuInterface[];
}

interface ToggleItemInterface {
    withAnimation: boolean;
    items: MenuIdentifierEnum[];
}

const NormalMenu: VFC<Props> = ({ menuItems }) => {
    const activeLinkRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const router = useRouter();
    const pseudoPathName = router.pathname + '/';

    useEffect(() => {
        if (activeLinkRef && activeLinkRef.current) {
            activeLinkRef.current.scrollIntoView({ block: 'center' });
        }
    }, []);

    const [itemsToggled, setItemToggled] = useState<ToggleItemInterface>({
        withAnimation: false,
        items: getMenu(),
    });

    const isItemToggled = (key: string, items: string[]): boolean =>
        items.includes(key);

    const onClickHeader = (identifier: MenuIdentifierEnum) => {
        const toggledItems = itemsToggled.items.includes(identifier)
            ? itemsToggled.items.filter((item) => item !== identifier)
            : [...itemsToggled.items, identifier];

        setItemToggled({ withAnimation: true, items: toggledItems });
        setMenu(toggledItems);
    };

    return (
        <>
            <Logo href={getRoute('/')} />
            <NormalMenuSC>
                <div className="scrollableWrapper">
                    <div className="scrollable">
                        {menuItems.map(
                            (
                                {
                                    text,
                                    identifier,
                                    justOnMobile,
                                    icon,
                                    iconStyle,
                                    items,
                                },
                                headerKey
                            ) => {
                                const activeItem = getActiveItem(
                                    items,
                                    pseudoPathName
                                );
                                const isHeaderOpened =
                                    !!activeItem ||
                                    isItemToggled(
                                        identifier,
                                        itemsToggled.items
                                    );

                                return (
                                    <div className="menuBlock" key={headerKey}>
                                        <NormalMenuItemHeader
                                            text={t(text)}
                                            icon={icon}
                                            iconStyle={iconStyle}
                                            justOnMobile={justOnMobile}
                                            key={headerKey}
                                            isOpened={isHeaderOpened}
                                            nonClickable={!!activeItem}
                                            onClick={
                                                activeItem
                                                    ? undefined
                                                    : () => {
                                                          onClickHeader(
                                                              identifier
                                                          );
                                                      }
                                            }
                                        />
                                        <div
                                            className={classNames(
                                                'toggleHeight',
                                                {
                                                    active: isHeaderOpened,
                                                    withAnimation:
                                                        itemsToggled.withAnimation,
                                                }
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    'itemBlock',
                                                    {
                                                        active: isHeaderOpened,
                                                        withAnimation:
                                                            itemsToggled.withAnimation,
                                                    }
                                                )}
                                            >
                                                {items.map(
                                                    (
                                                        {
                                                            configKey,
                                                            text,
                                                            title,
                                                            icon,
                                                            iconStyle,
                                                            link,
                                                        },
                                                        itemKey
                                                    ) => (
                                                        <NormalMenuItem
                                                            text={t(text)}
                                                            title={
                                                                title
                                                                    ? t(title)
                                                                    : undefined
                                                            }
                                                            icon={icon}
                                                            iconStyle={
                                                                iconStyle
                                                            }
                                                            forwardRef={
                                                                activeItem?.configKey ===
                                                                configKey
                                                                    ? activeLinkRef
                                                                    : undefined
                                                            }
                                                            active={
                                                                activeItem?.configKey ===
                                                                configKey
                                                            }
                                                            link={link}
                                                            justOnMobile={
                                                                justOnMobile
                                                            }
                                                            key={itemKey}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </NormalMenuSC>
        </>
    );
};

export default NormalMenu;
