import React, { ReactElement, useState, MutableRefObject } from 'react';
import classNames from 'classnames';
import { OverviewBackgroundColor, OverviewInterface } from './Overview';
import makeBlockFields from 'components/blocks/utils/makeBlockFields';
import { GroupEditFieldInterface } from 'components/interfaces/GeneralInterface';
import CardIcon, { CardIconType } from 'components/card/CardIcon';
import PromptModal from 'components/modal/PromptModal';
import { TagApiInterface } from 'submodules/api_middleware';
import TagBlock from 'components/pages/base/tagBlock/TagBlock';
import {
    OnSubmitData,
    OnSuccessData,
} from 'components/interfaces/EditFormInterface';
import styles from './Overview.module.scss';

interface ModalInterface {
    title: string;
    fields: GroupEditFieldInterface[];
    submit: OnSubmitData;
    onSuccess: OnSuccessData;
}

interface Props extends OverviewInterface {
    isArchived: boolean;
    tagsRef: MutableRefObject<HTMLDivElement>;
    tags: TagApiInterface[];
    internalTags: TagApiInterface[];
    showIndications: boolean;
    height: number;
    classNameInner: string;
}

const OverviewBlock = ({
    isEditable,
    fields,
    filterOutUnset,
    onEdit,
    onExport,
    whiteActions,
    backgroundColor,
    isArchived,
    tagsRef,
    tags,
    internalTags,
    showIndications,
    height,
    classNameInner,
}: Props): ReactElement => {
    const [showEdit, setShowEdit] = useState<ModalInterface>();

    const indications =
        ((!!tags || !!internalTags) && (
            <TagBlock internalTags={internalTags} tags={tags} />
        )) ||
        null;

    let action: CardIconType = null;
    if (!isArchived) {
        if (onExport) {
            action = { type: 'export', ...onExport };
        } else if (isEditable && !!onEdit) {
            action = {
                type: 'edit',
                isWhite: whiteActions,
                onClick:
                    typeof onEdit === 'function'
                        ? onEdit
                        : () =>
                              setShowEdit({
                                  title: onEdit.title,
                                  fields: onEdit.fields,
                                  submit: onEdit.submit,
                                  onSuccess: onEdit.onSuccess,
                              }),
            };
        }
    }

    return (
        <>
            {!!showEdit && (
                <PromptModal
                    title={showEdit.title}
                    fields={showEdit.fields}
                    showEditModal={true}
                    hideEditModalCb={() => setShowEdit(null)}
                    submit={showEdit.submit}
                    onSuccess={showEdit.onSuccess}
                    notCenteredFields
                />
            )}
            <div
                className={classNames(styles.block, {
                    [styles.toRight]:
                        (!internalTags || internalTags?.length === 0) &&
                        (!tags || tags?.length === 0),
                })}
                ref={tagsRef}
            >
                {showIndications && indications}
                {action && (
                    <CardIcon className={styles.reset} action={action} />
                )}
            </div>
            <div
                className={classNames(styles.wrapper, {
                    [styles.archivedBcg]: isArchived,
                    [styles.bcgRed]:
                        backgroundColor === OverviewBackgroundColor.red,
                    [styles.bcgDarkRed]:
                        backgroundColor === OverviewBackgroundColor.darkRed,
                    [styles.bcgDarkOrange]:
                        backgroundColor === OverviewBackgroundColor.darkOrange,
                    [styles.bcgDarkHighlight]:
                        backgroundColor ===
                        OverviewBackgroundColor.darkHighlight,
                    [styles.bcgGreen]:
                        backgroundColor === OverviewBackgroundColor.green,
                    [styles.bcgWhite]:
                        backgroundColor === OverviewBackgroundColor.white,
                })}
                style={{
                    height: `calc(100% - ${height}px - 1rem)`,
                }}
            >
                {makeBlockFields({
                    fields,
                    filterOutUnset,
                    classNameInner,
                })}
            </div>
        </>
    );
};

export default OverviewBlock;
