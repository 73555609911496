import { useEffect, useState } from 'react';
import fetcher, { MethodEnum } from 'utils/helpers/browser/newFetcher';

export interface MenuConfig {
    data: {
        is1Url?: string;
        is1RegistrationUrl?: string;
    };
}

export function useMenuConfig() {
    const [is1Url, setIs1Url] = useState(null);
    const [is1RegistrationUrl, setIs1RegistrationUrl] = useState(null);

    function fetchData(): void {
        fetcher<MenuConfig>('/api/menu/config', MethodEnum.GET).then((e) => {
            setIs1Url(e.data.is1Url);
            setIs1RegistrationUrl(e.data.is1RegistrationUrl);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return {
        is1Url,
        is1RegistrationUrl,
    };
}
