import React, { ReactElement, useEffect, useState } from 'react';
import AlertIcon from '../icon/icons/alert-2.svg';
import { PersonService } from 'services/person.service';
import Icon from 'components/icon/Icon';
import styles from './Alert.module.scss';

export const Alert = (): ReactElement => {
    const [alertMsg, setAlertMsg] = useState<string>(null);

    useEffect(() => {
        const getAlertMsg = async (): Promise<string | null> => {
            const response = await new PersonService().getAlert();
            return response.message;
        };

        getAlertMsg().then((msg) => setAlertMsg(msg));
    }, []);

    if (alertMsg) {
        return (
            <div className={styles.welcomeAlert}>
                <Icon icon={AlertIcon} className={styles.icon} style={styles} />
                <span>{alertMsg}</span>
            </div>
        );
    }

    return <></>;
};

export default Alert;
