import styled from 'styled-components';
import {
    colors,
    fontSize,
    fontWeights,
    ieOnly,
    max,
} from 'constants/constants.styled';
import A from 'components/layouts/A';

export default styled.div`
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    overflow-y: hidden;

    .menuBlock {
        position: relative;
        display: block;
        overflow: hidden;

        .toggleHeight {
            max-height: 0;
            overflow: hidden;

            &.withAnimation {
                transition: max-height 0.4s ease;
            }

            &.active {
                max-height: 1000px;
            }
        }

        .itemBlock {
            &.withAnimation {
                transform: translateY(-100%);
                transition: transform 0.4s ease;
            }

            &.active {
                transform: translateY(0);
            }
        }
    }

    .scrollableWrapper {
        position: relative;
        height: 100%;
    }

    .scrollable {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        overflow-y: scroll;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            position: absolute;
            width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }

        &:hover {
            scrollbar-color: rgba(0, 0, 0, 0.1) transparent;

            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.1);
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
`;

export const NormalMenuItemHeaderSC = styled.div`
    display: flex;
    align-items: center;
    height: 2rem;
    margin: 0.5rem 0;
    padding-left: 3.3rem;
    color: ${colors.main};
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: ${fontWeights.medium};
    font-size: ${fontSize.biggerText};
    cursor: pointer;

    .text {
        position: relative;
    }

    .toggleIcon {
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        margin-left: 0.25rem;
        vertical-align: center;
        border-top: 2px solid var(--colorSecondary);
        border-right: 2px solid var(--colorSecondary);
        transform: rotate(-45deg);

        &.open {
            margin-bottom: 0.25rem;
            transform: rotate(135deg);
        }
    }

    .icon {
        position: absolute;
        margin-left: -1.65rem;
        height: 1rem;
        margin-right: 1rem;
        fill: ${colors.main};
        stroke: ${colors.main};
        ${ieOnly`
            width: 1rem;
        `}
    }

    .flex {
        display: flex;
        flex-basis: 100%;

        .left {
            flex-grow: 1;
            display: flex;
            align-items: center;

            svg {
                width: 1rem;
                height: 1rem;
            }
        }

        .right {
            padding-right: 1rem;
        }
    }
`;

export const NormalMenuItemLinkSC = styled(A)`
    display: flex;
    align-items: center;
    height: 2rem;
    margin: 0.5rem 0;
    padding-left: 3.3rem;
    color: ${colors.main};
    font-weight: ${fontWeights.light};
    font-size: ${fontSize.text};
    text-transform: uppercase;
    text-decoration: none;

    &:hover,
    &:focus,
    &.active {
        color: var(--colorSecondaryHover);
        font-weight: ${fontWeights.semiBold};

        &::before {
            position: absolute;
            left: 0;
            width: 0.375rem;
            height: 2rem;
            background-color: var(--colorSecondary);
            content: '';
        }
    }

    .root {
        display: flex;
        align-items: center;
        height: 2rem;
        margin: 0.5rem 0;
        padding-left: 3.3rem;
        color: ${colors.main};
        text-transform: uppercase;
        text-decoration: none;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        font-weight: ${fontWeights.medium};
        font-size: ${fontSize.biggerText};
    }

    .icon {
        position: absolute;
        top: calc(50% - 0.5rem);
        left: -1.65rem;
        height: 1rem;
        margin-right: 1rem;
        fill: ${colors.main};
        stroke: ${colors.main};
        ${ieOnly`
            width: 1rem;
        `}
    }

    .text {
        position: relative;
        margin-bottom: 0;
    }

    .justOnMobile {
        display: none;

        ${max.xs`
            display: block;
        `}
    }
`;
