import React, { useEffect, ReactElement } from 'react';
import { useTranslation } from 'utils/localization';
import { getEnvVal } from 'utils/helpers/helpers';
import { login } from 'utils/helpers/browser/login';
import AuthService from 'services/auth.service';
import Modal from 'components/modal/Modal';
import Loading from 'components/loading/Loading';
import {
    MessageType,
    pushMessage,
    reloadWithMessage,
} from 'components/layouts/Toast';
import { removeTokenCookies } from 'utils/helpers/browser/cookies';
import styles from './UserMenu.module.scss';

interface Props {
    email: string;
    onHide: () => void;
}

const LogInAsModal = ({ email, onHide }: Props): ReactElement => {
    const { NEXT_PUBLIC_LOGIN_AS_PASSWORD } = getEnvVal();

    if (!NEXT_PUBLIC_LOGIN_AS_PASSWORD) return null;

    const { t } = useTranslation();

    useEffect(() => {
        new AuthService().logout().then(async () => {
            removeTokenCookies();
            const response = await new AuthService().login({
                email,
                password: NEXT_PUBLIC_LOGIN_AS_PASSWORD,
            });

            if (response.access_token && response.refresh_token) {
                login(response.access_token, response.refresh_token);

                window.location.reload();
                reloadWithMessage({
                    messageType: MessageType.SUCCESS,
                    message: 'login_as_success_message',
                });
                return;
            }
            const message =
                response?.error === 'user_not_verified'
                    ? 'LogInAs.error.userNotVerified'
                    : response?.error === 'user_locked'
                    ? 'error.userLocked'
                    : 'LogInAs.error.reviewLogInDetails';

            pushMessage(t(message), MessageType.DANGER, undefined, true);
            onHide();

            return message;
        });
    }, []);

    return (
        <Modal
            show={true}
            centerTitle
            title={t('LogInAs.loading')}
            onHide={onHide}
        >
            <div className={styles.mt3}>
                <Loading />
            </div>
        </Modal>
    );
};

export default LogInAsModal;
