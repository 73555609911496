import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { H1, H3 } from 'components/typography';
import styles from './PageTitle.module.scss';

export interface PageTitleProps {
    primaryBoldText?: string;
    primaryNormalText?: string;
    secondaryText?: string;
    text?: string;
    isDark?: boolean;
    isSmaller?: boolean;
    isCentered?: boolean;
}

const PageTitle = ({
    primaryBoldText,
    primaryNormalText,
    secondaryText,
    text,
    isDark,
    isSmaller,
    isCentered,
}: PageTitleProps): ReactElement => (
    <div>
        <H1
            className={classNames({
                [styles.whiteFont]: !isDark,
                [styles.smaller]: !!isSmaller,
                [styles.center]: isCentered,
            })}
        >
            <>
                {!!primaryNormalText && (
                    <span className={styles.lightFont}>
                        {primaryNormalText}
                    </span>
                )}
                {primaryBoldText ? ' ' + primaryBoldText : ''}
            </>
        </H1>
        {!!secondaryText && (
            <H3
                className={classNames({
                    [styles.whiteFont]: !isDark,
                })}
            >
                {secondaryText}
            </H3>
        )}
        {(!secondaryText || text) && (
            <span className={styles.text}>{text}</span>
        )}
    </div>
);

export default PageTitle;
