import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import { RoutesType } from 'constants/routes';
import { getDiscipline } from 'utils/helpers/browser/discipline';
import { encrypt } from 'utils/helpers/hash';
import { DisciplineEnum } from 'submodules/api_middleware';
import { ProtestExportTypeEnum } from 'pages/local/export/pdf/matches_protest_export';

const PDF_CONTENT_URL_PREFIX = '/local/export/pdf';

const PdfContentTypes = [
    '/account_print',
    '/matches_match_sum_export',
    '/matches_home_export',
    '/matches_visitor_export',
    '/matches_protest_export',
    '/players_passport_export',
    '/players_gdpr_export',
    '/members_print_envelope',
    '/competitions_matches_export',
    '/delegations_export',
    '/application_export',
    '/international_transfer_certificate_export',
] as const;

export type PdfContentRoutesType = typeof PdfContentTypes[number];

const MIDDLEWARE_URL = '/api/export';

const FILE_NAME_KEY = 'file_name';
const DISCIPLINE_KEY = 'discipline';
const ROUTE_KEY = 'route';

interface QueryInterface {
    [key: string]: string | string[];
}

export type ParamsType = QueryInterface & { discipline?: DisciplineEnum };

const getUrlParams = (
    query: QueryInterface,
    urlParams: URLSearchParams = new URLSearchParams()
) => {
    Object.entries(query).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((v) => urlParams.append(key, v));
        } else {
            urlParams.append(key, value);
        }
    });

    return urlParams;
};

export const getPdfContentLinkFromBrowser = (
    route: PdfContentRoutesType,
    specificFileName: string,
    params: ParamsType
): string => {
    const disc = params.discipline || getDiscipline(undefined);

    let urlParams = new URLSearchParams({
        [DISCIPLINE_KEY]: disc,
        [ROUTE_KEY]: route,
    });

    urlParams = getUrlParams(params, urlParams);

    const hash = encrypt(urlParams.toString());
    return (
        MIDDLEWARE_URL +
        `/${specificFileName}` +
        `?params=${encodeURIComponent(hash.toString())}`
    );
};

export const getPdfContentLink = (
    query: NextApiRequestQuery
): { urlForPuppeteer: string; fileName: string } => {
    const partialRoute: string = query[`${ROUTE_KEY}`] as string;
    const fileName = query.slug as string;
    const route: RoutesType = enumToRouteType(partialRoute);

    delete query[`${FILE_NAME_KEY}`];
    delete query[`${ROUTE_KEY}`];

    const params = getUrlParams(query);
    return {
        urlForPuppeteer: `${route}?${params}`,
        fileName,
    };
};

const enumToRouteType = (route: string): RoutesType => {
    // @ts-ignore-next-line
    if (PdfContentTypes.indexOf(route) === -1) {
        throw new Error('url not allowed');
    }
    //@ts-ignore-next-line
    return PDF_CONTENT_URL_PREFIX + route;
};

export const openProtestExportLinkInBrowser = (
    fileName: string,
    ids: number | number[],
    type: ProtestExportTypeEnum,
    discipline?: DisciplineEnum
) => {
    openPdfContentLinkFromBrowser('/matches_protest_export', fileName, {
        id: Array.isArray(ids) ? ids.map((id) => id + '') : ids + '',
        type,
        discipline,
    });
};

export const openPdfContentLinkFromBrowser = (
    route: PdfContentRoutesType,
    specificFileName: string,
    params: ParamsType
) => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(
        getPdfContentLinkFromBrowser(route, specificFileName, params),
        '_blank'
    );
};
