import { useLayoutEffect, useEffect } from 'react';

const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const useWindowChange = (
    callBack: (width: number, height: number) => any
): void => {
    useIsomorphicLayoutEffect(() => {
        const update = () => {
            callBack(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, []);
    return;
};
