import { MenuItemInterface } from './Menu';
import {
    LinkGeneralInterface,
    LinkInterface,
} from 'components/interfaces/GeneralInterface';

export const getActiveLink = (
    links: LinkGeneralInterface[],
    currentRoutePath: string
): LinkInterface => {
    const innerLinks: LinkInterface[] = [];
    links.forEach((link) => {
        innerLinks.push({
            idx: link.idx,
            link: link.link,
            blankPage: link.blankPage,
            text: link.text,
        });

        link.items?.forEach((item) => innerLinks.push(item));
    });

    const linksWithCommonBase = innerLinks.filter((l) => {
        const linkNoDiscipline = l.link ? l.link.split('?')[0] : l.link;
        // eslint-disable-next-line security/detect-non-literal-regexp
        const regex = new RegExp(`^${linkNoDiscipline}`);
        return regex.test(currentRoutePath);
    });
    return linksWithCommonBase.sort((a, b) =>
        a.link.length > b.link.length ? -1 : 1
    )[0];
};

export const getActiveItem = (
    items: MenuItemInterface[],
    pseudoPathName: string
): MenuItemInterface => {
    return items.find(({ link }) =>
        pseudoPathName.startsWith(link ? link.split('?')[0] + '/' : link)
    );
};
