import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { addParamsToRoute, attachDisciplineToUrl } from 'constants/routes';
import { useTranslation } from 'utils/localization';
import { getPathToString } from 'constants/constants';
import { Text } from 'components/typography';
import A from 'components/layouts/A';
import styles from './Breadcrumbs.module.scss';

interface Props {
    onDarkBackground?: boolean;
}

interface Crumb {
    key: string;
    path: string;
}

const Breadcrumbs = ({ onDarkBackground }: Props): ReactElement => {
    const router = useRouter();

    const splits = router.pathname.replace(/^\/|\/$/gm, '').split('/');
    const { t } = useTranslation();
    const pathToString = getPathToString(t);
    const regex = /^\[.*]$/;
    let pathString = '';
    let keyString = '';
    let keySplitCounter = 0;

    const queryPathValues: string[] = [];
    const crumbs: Crumb[] = splits.map((split) => {
        let pathSplit: string = split;
        let keySplit: string = split;
        if (regex.test(split)) {
            const queryKey: string = split.substr(1, split.length - 2);
            pathSplit = router.query[`${queryKey}`] as string;
            queryPathValues.push(pathSplit);
            keySplit = '{' + keySplitCounter + '}';
            keySplitCounter++;
        }
        return {
            key: (pathString += '/' + keySplit.toLowerCase()),
            path: (keyString += '/' + pathSplit.toLowerCase()),
        };
    });

    const validPathWithValues = Object.keys(pathToString)
        .filter((key) => key.startsWith('/' + splits[0]))
        .reduce((result: Record<string, any>, key) => {
            result[`${addParamsToRoute(key, queryPathValues)}`] =
                pathToString[`${key}`];
            return result;
        }, {});

    const crumbsFiltered = crumbs.filter(
        ({ key, path }) =>
            !!pathToString[`${key}`] || !!validPathWithValues[`${path}`]
    );

    const getArrowElement = (reactKey: string): ReactElement => (
        <span
            key={reactKey}
            className={classNames(styles.next, {
                [styles.white]: onDarkBackground,
            })}
        >
            {'>'}
        </span>
    );

    return (
        <div>
            {crumbsFiltered.map((crumb, i) => {
                const crumbPath = validPathWithValues[crumb.path];
                if (crumbPath.nonClickable || i + 1 === crumbsFiltered.length) {
                    return (
                        <span key={i}>
                            <Text
                                className={
                                    onDarkBackground ? styles.white : undefined
                                }
                            >
                                {crumbPath.label}
                            </Text>
                            {crumbPath.nonClickable &&
                                getArrowElement(`arrow-${i}`)}
                        </span>
                    );
                }
                return (
                    <span key={i}>
                        <A
                            className={classNames(styles.crumb, {
                                [styles.white]: onDarkBackground,
                            })}
                            href={attachDisciplineToUrl(crumb.path)}
                        >
                            {crumbPath.label}
                        </A>
                        {getArrowElement(`arrow-${i}`)}
                    </span>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
