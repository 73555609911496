import React, { ReactElement, useContext } from 'react';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import { getRoute } from 'constants/routes';
import { UserMe } from 'services/user.service';
import ContextMenu, {
    ContextMenuOption,
} from 'components/contextMenu/ContextMenu';
import { getTranslationKey, useTranslation } from 'utils/localization';
import { GlobalContext } from 'pages/_app';
import { MenuApiEnum } from 'components/interfaces/GeneralInterface';
import { TNamespaceEnum } from 'types/types';
import { getLocaleDateString } from 'utils/helpers/getLocalDateString';
import { setTokenCookie } from 'utils/helpers/browser/cookies';
import { TokenPurpose } from 'utils/helpers/cookies.shared';
import { MessageType, redirectWithMessage } from 'components/layouts/Toast';
import AuthService from 'services/auth.service';
import styles from './UserMenu.module.scss';

interface Props {
    user: UserMe;
}

const UserMenu = ({ user }: Props): ReactElement => {
    const Router = useRouter();
    const { t } = useTranslation();
    const { menu } = useContext(GlobalContext);

    const allowPassChange = menu.includes(MenuApiEnum['user-set-password']);

    const options: ContextMenuOption[] = [
        ...(allowPassChange
            ? [
                  {
                      text: t('userMenu.passwordChange'),
                      onClick: () => {
                          Router.push(getRoute('/user/password'));
                      },
                  },
              ]
            : []),
        {
            text: t('userMenu.myProfile'),
            url: getRoute('/members/{0}', [user && user.person_id]),
        },
        {
            text: t('userMenu.logout'),
            onClick: () => {
                Router.push(getRoute('/logout'));
            },
        },
    ];

    const dateFormat = getLocaleDateString();

    const personSwitch =
        user.user_persons.length == 1
            ? [] //If there is just single person, we don't want to display any options apart from 'Sign out'.
            : user.user_persons.map(
                  ({
                      person_id,
                      name,
                      date_of_birth,
                      gender,
                      has_matured,
                  }): ContextMenuOption => ({
                      text: t(
                          getTranslationKey(
                              TNamespaceEnum.common,
                              'user.personToSwitch'
                          ),
                          {
                              name: name,
                              birthDate: format(
                                  new Date(date_of_birth),
                                  dateFormat
                              ),
                              suffix: t(
                                  getTranslationKey(
                                      TNamespaceEnum.common,
                                      user.person_id === person_id
                                          ? gender === 'male'
                                              ? 'user.signedInMale'
                                              : 'user.signedInFemale'
                                          : has_matured
                                          ? gender === 'male'
                                              ? 'user.matureMale'
                                              : 'user.matureFemale'
                                          : 'user.noSuffix'
                                  )
                              ),
                          }
                      ),
                      // We don't want the currently selected user having an active link:
                      isDisabled: user.person_id === person_id || has_matured,
                      onClick: async () => {
                          new AuthService()
                              .switchUserPerson({ person_id })
                              .then(async (res) => {
                                  // Error when switching person
                                  if (!res.access_token) return;

                                  // Keep refresh cookie same, only selected person has been changed
                                  setTokenCookie(
                                      res.access_token,
                                      TokenPurpose.Access
                                  );

                                  // Redirect to home
                                  redirectWithMessage('/', {
                                      messageType: MessageType.SUCCESS,
                                      message: 'user_switch_success_message',
                                  });
                              });
                      },
                  })
              );

    options.push(...personSwitch);

    // TODO IT-332: check if registration is possible
    options.push({
        text: t('userMenu.registerMinor'),
        onClick: () => {
            Router.push(getRoute('/signup'));
        },
    });
    options.push({
        text: t('userMenu.personRecovery'),
        onClick: () => {
            Router.push(getRoute('/person-recovery'));
        },
    });

    return (
        <ContextMenu options={options}>
            <div className={styles.userMenu}>
                <span className={styles.userName}>{user && user.name}</span>
                <span className={styles.icon} />
            </div>
        </ContextMenu>
    );
};

export default UserMenu;
