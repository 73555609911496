import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { isSystemDowngraded } from 'utils/helpers/helpers';
import styles from './ProfileImg.module.scss';

export interface Props {
    imageUrl?: string;
    imageReplacementText?: string[];
}

const ProfileImg = ({
    imageUrl,
    imageReplacementText,
}: Props): ReactElement => {
    const replaceImageWithText =
        imageReplacementText && imageReplacementText.length === 2;
    return (
        <div
            style={{
                backgroundImage: imageUrl
                    ? `url('${imageUrl}')`
                    : replaceImageWithText
                    ? undefined
                    : `url('/images/logo_blue.svg')`,
            }}
            className={classNames(styles.profileImage, {
                [styles.logo]: !imageUrl,
                [styles.downgraded]: isSystemDowngraded(),
            })}
        >
            {replaceImageWithText && (
                <div className={styles.text}>
                    {imageReplacementText.map((text) => (
                        <div key={text}>{text}</div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ProfileImg;
