import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import LogInAsModal from './LogInAsModal';
import ContextMenu, {
    ContextMenuOption,
} from 'components/contextMenu/ContextMenu';
import { useTranslation } from 'utils/localization';

import { getEnvVal } from 'utils/helpers/helpers';
import { RequireAtLeastOne } from 'types/types';
import styles from './UserMenu.module.scss';

interface AccountInterface {
    label: string;
    labelKey: string;
    email: string;
}

const LogInAs = (): ReactElement => {
    const { NEXT_PUBLIC_LOGIN_AS_PASSWORD } = getEnvVal();

    if (!NEXT_PUBLIC_LOGIN_AS_PASSWORD) return null;

    const { t } = useTranslation();
    const [logIn, setLogIn] = useState<string>();

    const options = getAccounts().map(
        ({ label, labelKey, email }): ContextMenuOption => ({
            text: label ? label : t(labelKey),
            onClick: () => setLogIn(email),
        })
    );

    return (
        <>
            {!!logIn && (
                <LogInAsModal onHide={() => setLogIn(null)} email={logIn} />
            )}
            <ContextMenu options={options}>
                <div
                    className={classNames(
                        styles.userMenu,
                        styles.disciplineMenu
                    )}
                >
                    <span className={styles.userName}>
                        {t('LogInAs.title')}
                    </span>
                    <span className={styles.icon} />
                </div>
            </ContextMenu>
        </>
    );
};

export default LogInAs;

const getAccounts = (): RequireAtLeastOne<
    AccountInterface,
    'label' | 'labelKey'
>[] => [
    { label: 'Super admin', email: 'test@certisys.cz' },
    {
        label: 'AČK - Viktoria Žižkov',
        email: 'member-u-15@certisys.cz',
    },
    {
        label: 'AČK - Sparta Praha',
        email: 'member-u-19@certisys.cz',
    },
    {
        label: 'AČK - Bohemia Praha',
        email: 'club-3-admin@certisys.cz',
    },
    {
        label: 'AČK - SK Slavia Praha - fotbal a.s.',
        email: 'club-4-admin@certisys.cz',
    },
    {
        label: 'AČK - Fotbalový klub Varnsdorf a. s.',
        email: 'club-5-admin@certisys.cz',
    },
    {
        label: 'STK - LFA',
        email: 'member-u-58-sport_technical_commission-2@certisys.cz',
    },
    {
        label: 'STK - KFS Hl. město Praha',
        email: 'member-u-59-sport_technical_commission-7@certisys.cz',
    },
    {
        label: 'STK - OFS České Budějovice',
        email: 'member-u-60-sport_technical_commission-22@certisys.cz',
    },
    {
        label: 'DK - LFA',
        email: 'LFA-football-disciplinary_commission-admin@certisys.cz',
    },
    {
        label: 'DK - KFS Hl. město Praha',
        email: 'PHA-football-disciplinary_commission-admin@certisys.cz',
    },
    {
        label: 'DK - OFS České Budějovice',
        email: 'CB-football-disciplinary_commission-admin@certisys.cz',
    },
    {
        label: 'Rozhodčí - u10',
        email: 'member-u-10-referee-football@certisys.cz',
    },
    {
        label: 'Delegát - u12',
        email: 'member-u-12-delegate-football@certisys.cz',
    },
    {
        label: 'Registrace členství',
        email: 'memberships_admin_group_admin@certisys.cz',
    },
    {
        label: 'Registrace hráčů',
        email: 'player_registration_admin_group_admin@certisys.cz',
    },
    {
        label: 'Registrace uživatelů',
        email: 'users_registration_admin_group_admin@certisys.cz',
    },
];
