import React, { VFC } from 'react';
import classNames from 'classnames';
import LogoSvg from '../icon/icons/logo.svg';
import A from 'components/layouts/A';
import styles from './Logo.module.scss';

interface Props {
    className?: string;
    href?: string;
}

const Logo: VFC<Props> = ({ className, href }) => {
    if (!href) {
        return <LogoSvg className={classNames(styles.root, className)} />;
    }

    return (
        <A href={href}>
            <LogoSvg
                className={classNames(styles.root, className, styles.clickable)}
            />
        </A>
    );
};

export default Logo;
