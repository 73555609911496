import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Gradient.module.scss';

interface Props {
    colors?: string[];
    topColor?: string;
    className?: string;
    minimizeGradient?: boolean;
    biggestGradient?: boolean;
}

const getSvg = (colors: string[], topColor: string): string => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1560" height="721" viewBox="0 0 1560 721" preserveAspectRatio="none">
  <style type="text/css">
    .filter{opacity: .1;}
  </style>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_144" data-name="Rectangle 144" width="1560" height="721" transform="translate(360)" fill="#fff" stroke="#707070" stroke-width="1"/>
    </clipPath>
    <filter id="Path_54" x="-478.002" y="-356.457" width="2743.338" height="1471.288" filterUnits="userSpaceOnUse">
      <feOffset dy="30" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="30" result="blur"/>
      <feFlood flood-color="#4b3cb9" flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <linearGradient id="linear-gradient" x1="0.574" y1="-0.631" x2="0.27" y2="1.423" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="${colors[0]}"/>
      <stop offset="0.252" stop-color="${colors[1]}"/>
      <stop offset="0.511" stop-color="${colors[2]}"/>
      <stop offset="0.731" stop-color="${colors[3]}"/>
      <stop offset="0.901" stop-color="${colors[4]}"/>
      <stop offset="1" stop-color="${colors[5]}"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.574" y1="-0.631" x2="0.27" y2="1.423" gradientUnits="objectBoundingBox" class="filter">
      <stop offset="0" stop-color="${topColor}" class="filter"/>
      <stop offset="0.138" stop-color="${topColor}" class="filter"/>
      <stop offset="0.35" stop-color="${topColor}" class="filter"/>
      <stop offset="0.793" stop-color="${topColor}" class="filter"/>
      <stop offset="0.901" stop-color="${topColor}" class="filter"/>
      <stop offset="1" stop-color="${topColor}" class="filter"/>
    </linearGradient>
  </defs>
  <g id="Mask_Group_2" data-name="Mask Group 2" transform="translate(-360)" clip-path="url(#clip-path)">
    <g id="Group_94" data-name="Group 94" transform="translate(170.137 -379.63) rotate(16)">
      <path id="Path_56" data-name="Path 56" d="M919.944,395.593c411.685-164.57,726.08-327.83,1032.217-112.2,297.612,209.621,544.593-17.975,544.593-17.975l39.782-413.98L-181.771-50.816S340.628,627.175,919.944,395.593Z" transform="translate(9.021 87.865)" fill="url(#linear-gradient)"/>
      <path id="Path_55" data-name="Path 55" d="M919.944,395.593C1331.629,231.023,1608.474,73.376,1914.611,289c297.611,209.621,582.142-23.588,582.142-23.588l23.881-281.369L201.343-217.754S340.628,627.175,919.944,395.593Z" transform="translate(236.216 -141.882)" fill="url(#linear-gradient-2)"/>
    </g>
  </g>
</svg>
`;

const Gradient = ({
    colors = ['red', '#bc0098', '#80009c', '#53009e', '#3800a0', '#2e00a1'],
    topColor = '#2e00a1',
    className,
    minimizeGradient,
    biggestGradient,
}: Props): ReactElement => (
    <div className={classNames(styles.wrapper, className)}>
        {/* eslint-disable @next/next/no-img-element */}
        <img
            src={`data:image/svg+xml;base64,${Buffer.from(
                getSvg(colors, topColor),
                'binary'
            ).toString('base64')}`}
            className={classNames(styles.gradientRoot, {
                [styles.minimizeGradient]: !!minimizeGradient,
                [styles.biggestGradient]: !!biggestGradient,
            })}
        />
    </div>
);

export default Gradient;
