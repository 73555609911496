import React, { ReactElement, useState } from 'react';
import PhotoUploader, {
    PhotoUploaderAcceptTypesEnum,
    PhotoUploaderAspectEnum,
    PhotoUploaderCropShapeType,
} from 'components/photoUploader/PhotoUploader';
import Modal from 'components/modal/Modal';
import Loading from 'components/loading/Loading';

interface Props {
    show: boolean;
    onHideCb: () => void;
    value?: string;
    isLoading?: boolean;
    setValue: (photo: string) => void;
    aspect?: PhotoUploaderAspectEnum;
    cropShape?: PhotoUploaderCropShapeType;
    acceptTypes?: PhotoUploaderAcceptTypesEnum[];
    help?: string;
    helpKey?: string;
}

const UploadPhotoModal = ({
    show,
    onHideCb,
    value,
    setValue,
    isLoading,
    aspect,
    cropShape,
    acceptTypes,
    help,
    helpKey,
}: Props): ReactElement => {
    const [tempPhoto, setTempPhoto] = useState<string>('');
    return (
        <Modal
            show={show}
            onHide={onHideCb}
            showCross
            size={help || helpKey ? 'XL' : 'L'}
        >
            {isLoading ? (
                <Loading />
            ) : (
                <PhotoUploader
                    value={value}
                    setValue={setValue}
                    setTempPhoto={setTempPhoto}
                    tempPhoto={tempPhoto}
                    acceptTypes={acceptTypes}
                    aspect={aspect}
                    help={help}
                    helpKey={helpKey}
                    cropShape={cropShape}
                />
            )}
        </Modal>
    );
};
export default UploadPhotoModal;
