import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { WarningInterface } from './Overview';
import { H2 } from 'components/typography';
import styles from './Overview.module.scss';

interface Props extends WarningInterface {
    isArchived: boolean;
    height: number;
}

const OverviewWarningBlock = ({
    warnings,
    successTitle,
    title,
    isArchived,
    height,
    hideOnSuccessState,
    display,
}: Props): ReactElement => {
    if (!display || (hideOnSuccessState && warnings.length === 0)) {
        return null;
    }

    return (
        <div
            style={{
                display: 'block',
                height: `calc(100% - ${height}px - 1rem)`,
            }}
        >
            <div style={{ height: `${height}px` }}></div>
            <div
                className={classNames(styles.warningWrapper, {
                    [styles.archivedBcg]: isArchived,
                    [styles.successBlock]: warnings.length === 0,
                })}
            >
                {(warnings.length === 0 && (
                    <div className={styles.noWarnings}>
                        <H2 className={styles.bold}>{successTitle}</H2>
                    </div>
                )) || (
                    <>
                        {title && <H2 className={styles.bold}>{title}</H2>}
                        <div className={styles.content}>
                            <ul>
                                {warnings.map((warning, key) => (
                                    <li key={key}>{warning}</li>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default OverviewWarningBlock;
