import React, { ReactElement, useState } from 'react';
import { TFunction } from 'next-i18next';
import TopControlBlockFormModal from './TopControlBlockFormModal';
import TopControlBlockSC from './TopControlBlock.styled';
import Button from 'components/forms/Button';
import { useTranslation } from 'utils/localization';
import ContextMenu from 'components/contextMenu/ContextMenu';
import {
    ConfigActionsApiInterface,
    ConfigModuleApiInterface,
    DataType,
    ValuesStringInterface,
} from 'submodules/api_middleware';
import {
    GroupEditFieldInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import ButtonLoader from 'components/loading/ButtonLoader';
import ConfirmModal from 'components/modal/ConfirmModal';
import { ApiResponseInterface } from 'utils/helpers/browser/getResponse';
import { FormResponseData } from 'services/user.service';
import { RequireAtLeastOne } from 'types/types';
import { MessageType, reloadWithMessage } from 'components/layouts/Toast';
import {
    FileStateInterface,
    OnSuccessType,
} from 'components/interfaces/EditFormInterface';
import {
    openPdfContentLinkFromBrowser,
    ParamsType,
    PdfContentRoutesType,
} from 'services/pdf.service';

interface Props {
    actions: ConfigActionsApiInterface<string, string>;
    actionMap: ActionMapInterface;
    isLoading?: boolean;
}

interface ConfirmModalProps {
    titleKey?: string;
    title?: string;
    textKey?: string;
    text?: string;
    cb: (data: DataType) => Promise<FormResponseData>;
    ownCb: (data: DataType) => OnSuccessType;
}

interface ConfirmInnerModalProps
    extends Omit<Omit<ConfirmModalProps, 'cb'>, 'ownCb'> {
    cb: () => Promise<FormResponseData>;
    ownCb: () => OnSuccessType;
}

interface FormModalProps {
    title: string;
    fields: GroupEditFieldInterface[];
    cb: (
        data: DataType,
        values: StateInterface,
        files: FileStateInterface,
        changedValues: StateInterface
    ) => Promise<FormResponseData>;
    onSuccess?: (
        values?: ValuesStringInterface,
        files?: FileStateInterface,
        response?: ApiResponseInterface
    ) => OnSuccessType;
}

export interface ActionInterface {
    localization: string;
    cb?: (data: DataType) => void;
    confirmModal?: RequireAtLeastOne<ConfirmModalProps, 'cb' | 'ownCb'>;
    formModal?: FormModalProps;
}

interface ActionInnerInterface {
    localization: string;
    cb?: () => void;
    confirmModal?: ConfirmInnerModalProps;
    formModal?: FormModalInnerProps;
}

export interface FormModalInnerProps extends FormModalProps {
    cb: (
        values: StateInterface,
        files: FileStateInterface,
        changedValues: StateInterface
    ) => Promise<FormResponseData>;
}

export interface ActionMapInterface {
    [key: string]: ActionInterface;
}

const onCofirmModalAction = async (
    t: TFunction,
    label: string,
    cb: () => Promise<FormResponseData>
): Promise<boolean> => {
    const response = await cb();
    if (!response.error && !response.errors) {
        reloadWithMessage({
            message: t('TopControlConfirmModal.default_confirm_action_text', {
                label,
            }),
            messageType: MessageType.SUCCESS,
            translated: true,
        });
    } else {
        return true;
    }

    return false;
};

const getMenuActions = (
    menuActions: Record<string, ConfigModuleApiInterface>,
    actionMap: ActionMapInterface
): ActionInnerInterface[] => {
    if (menuActions) {
        const actions: ActionInnerInterface[] = [];
        for (const property in menuActions) {
            const mAction = menuActions[`${property}`];
            if (mAction?.enabled && mAction.data?.export) {
                actions.push({
                    localization: mAction.label,
                    cb: () =>
                        openPdfContentLinkFromBrowser(
                            `/${mAction.data?.export.params.type}` as PdfContentRoutesType,
                            mAction.data?.export.file_name,
                            mAction.data?.export.params as unknown as ParamsType
                        ),
                });
            } else if (mAction?.enabled && actionMap[`${property}`]) {
                const action = actionMap[`${property}`];
                actions.push({
                    localization: mAction.label || action.localization,
                    cb: action.cb ? () => action.cb(mAction.data) : undefined,
                    confirmModal: action.confirmModal
                        ? {
                              text: action.confirmModal.text,
                              textKey: action.confirmModal.textKey,
                              title: mAction.label || action.confirmModal.title,
                              titleKey: action.confirmModal.titleKey,
                              cb: action.confirmModal.cb
                                  ? () => action.confirmModal.cb(mAction.data)
                                  : undefined,
                              ownCb: action.confirmModal.ownCb
                                  ? () =>
                                        action.confirmModal.ownCb(mAction.data)
                                  : undefined,
                          }
                        : undefined,
                    formModal: action.formModal
                        ? {
                              ...action.formModal,
                              cb: (
                                  values: StateInterface,
                                  files: FileStateInterface,
                                  changedValues: StateInterface
                              ) =>
                                  action.formModal.cb(
                                      mAction.data,
                                      values,
                                      files,
                                      changedValues
                                  ),
                          }
                        : undefined,
                });
            }
        }
        return actions;
    }
    return [];
};

const TopControlBlock = ({
    isLoading,
    actions,
    actionMap,
}: Props): ReactElement => {
    if (!actions || !actionMap) return null;

    const { t } = useTranslation();
    const actionsMenu = getMenuActions(actions.menu, actionMap);
    const [confirmModalData, setConfirmModalData] =
        useState<ConfirmInnerModalProps>(null);
    const [formModalData, setFormModalData] =
        useState<FormModalInnerProps>(null);

    if (actionsMenu.length === 0) return null;
    const mainAction = actionsMenu.shift();

    const onClick = (action: ActionInnerInterface) => {
        if (action.formModal) {
            setFormModalData(action.formModal);
        } else if (action.confirmModal) {
            setConfirmModalData(action.confirmModal);
        } else {
            action.cb();
        }
    };

    return (
        <>
            {!!confirmModalData && (
                <ConfirmModal
                    show={true}
                    title={
                        confirmModalData.title || t(confirmModalData.titleKey)
                    }
                    text={
                        confirmModalData.text ||
                        (confirmModalData.textKey
                            ? t(confirmModalData.textKey)
                            : t('TopControlConfirmModal.default_text'))
                    }
                    onConfirm={
                        confirmModalData.ownCb
                            ? confirmModalData.ownCb
                            : () =>
                                  onCofirmModalAction(
                                      t,
                                      confirmModalData.title,
                                      confirmModalData.cb
                                  )
                    }
                    onHide={() => setConfirmModalData(null)}
                />
            )}
            {!!formModalData && (
                <TopControlBlockFormModal
                    formModalData={formModalData}
                    setFormModalData={setFormModalData}
                />
            )}
            <TopControlBlockSC>
                {!!mainAction && (
                    <Button
                        className={'topControlBlockButton'}
                        onClick={() => onClick(mainAction)}
                        isSecondary
                        isDisabled={isLoading}
                        resolveImmediately
                    >
                        {isLoading ? (
                            <ButtonLoader />
                        ) : (
                            (t(mainAction.localization) as string)
                        )}
                    </Button>
                )}
                {actionsMenu.length > 0 && (
                    <ContextMenu
                        options={actionsMenu.map((action) => {
                            return {
                                text: t(action.localization),
                                onClick: () => onClick(action),
                            };
                        })}
                        isButton
                        resolveButtonImmediately
                        buttonProps={{ isWhite: true }}
                        isDisabled={isLoading}
                    />
                )}
            </TopControlBlockSC>
        </>
    );
};

export default TopControlBlock;
