import React, { ReactElement } from 'react';
import style from './EnvironmentBar.module.scss';

/**
 * A bar that displays the current environment as long as it's not a production environment.
 */
function EnvironmentBar(): ReactElement {
    const environment: string = process.env.NODE_ENV;

    if (environment === 'production') {
        return <></>;
    }

    const environmentNameDisplay =
        environment.charAt(0).toUpperCase() + environment.slice(1);
    return (
        // eslint-disable-next-line security/detect-object-injection
        <div className={style.environmentBar + ' ' + style[environment]}>
            {environmentNameDisplay}
        </div>
    );
}

export default EnvironmentBar;
