import React, { ReactElement } from 'react';
import EditForm from 'components/editForm/EditForm';
import { GroupEditFieldInterface } from 'components/interfaces/GeneralInterface';
import Modal from 'components/modal/Modal';
import {
    EditFormDataInterface,
    EditFormOnFieldChangeType,
    OnSubmitData,
    OnSuccessData,
} from 'components/interfaces/EditFormInterface';
import styles from './PromptModal.module.scss';

interface Props {
    fields: GroupEditFieldInterface[];
    showEditModal: boolean;
    hideEditModalCb: () => void;
    submit: OnSubmitData;
    onSuccess?: OnSuccessData;
    nextButtonTextKey?: string;
    title?: string;
    notCenteredFields?: boolean;
    getFormData?: (data: EditFormDataInterface) => void;
    getOnFieldChange?: (onFieldChange: EditFormOnFieldChangeType) => void;
}

const PromptModal = ({
    fields,
    showEditModal,
    hideEditModalCb,
    submit,
    onSuccess,
    nextButtonTextKey,
    title,
    notCenteredFields,
    getFormData,
    getOnFieldChange,
}: Props): ReactElement => (
    <Modal
        show={showEditModal}
        onHide={hideEditModalCb}
        showCross
        className={styles.inputs}
        title={title}
        centerTitle
    >
        <EditForm
            center={!notCenteredFields}
            fields={fields}
            submit={submit}
            onSuccess={onSuccess}
            withCancelChangesBtn
            cancelButtonTextKey={'actions.cancel'}
            cancel={hideEditModalCb}
            centerButtons
            buttonTextKey={nextButtonTextKey}
            getFormData={getFormData}
            getOnFieldChange={getOnFieldChange}
        />
    </Modal>
);

export default PromptModal;
