import styled from 'styled-components';
import { colors, max } from 'constants/constants.styled';

export default styled.div`
    position: relative;
    z-index: 2;
    width: 22.5rem;
    transition: width 0.5s ease-out;
    flex-shrink: 0;

    &.collapsed {
        width: 8rem;
    }

    ${max.xl`
        width: 18rem;
    `}

    ${max.lg`
        width: 16rem;
    `}

    ${max.xs`
        width: unset;
        flex-shrink: 1;
        &.collapsed {
            width: unset;
        }
    `}

    .root {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 22.5rem;
        height: 100vh;
        padding-top: 3.75rem;
        background-color: ${colors.white};
        transition: width 0.5s ease-out;

        &.collapsed {
            width: 8rem;
            transition: width 0.5s ease-out;
        }

        ${max.xl`
            width: 18rem;
        `}

        ${max.lg`
            width: 16rem;
        `}

        ${max.xs`
            width: unset;
            &.collapsed {
                width: unset;
            }
        `}
    }

    .menu {
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 1;
        flex-shrink: 0;
        height: 100vh;
        transition: 0.25s ease-in;

        ${max.xs`
            position: fixed;
            top: 0;
            bottom: 0;
            left: -70%;
            z-index: 500;
            width: 70%;
            min-width: 16px;
            transition: left 0.5 ease-in-out;

            &.isToggled::before {
                position: absolute;
                top: 0;
                right: -43%;
                bottom: 0;
                left: 0;
                z-index: -1;
                background-color: ${colors.menuBackdrop};
                content: ' ';
            }

            &.isToggled {
                left: 0;
            }

            &.isToggled > .root > .cross {
                display: block;
            }

            &.isToggled > .burgerIcon {
                opacity: 0;
            }

            &.isToggled > .root {
                padding-top: 3rem;
            }

            &.isToggled > .root > svg {
                position: absolute;
                top: calc(50%);
                left: 110%;
                height: 4rem;
            }
        `}
    }

    .normalMenuContainer,
    .smallMenuContainer {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        &.show {
            display: flex;
        }
    }

    ${max.xs`
        .normalMenuContainer {
            display: flex;

            &.show {
                display: flex;
            }
        }

        .smallMenuContainer {
            display: none;

            &.show {
                display: none;
            }
        }
    `}

    .burgerIcon {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        width: 4.375rem;
        height: 4.375rem;
        padding: 1rem;
        background-color: ${colors.secondary};
        box-shadow: 5px 6px 12px 1px #0014582e;
        transition: opacity 0.1s ease;

        ${max.xs`
            display: flex;
        `}

        &:hover {
            cursor: pointer;
        }

        div {
            position: relative;
            width: 100%;
            height: 0.2rem;
            background: ${colors.white};
        }
    }

    .cross {
        position: absolute;
        top: 0;
        right: -43%;
        display: none;
        width: 4.375rem;
        height: 4.375rem;

        &:hover {
            cursor: pointer;
        }

        div {
            position: absolute;
            top: 2.2rem;
            left: 0;
            width: 80%;
            height: 0.2rem;
            background-color: ${colors.white};
        }

        div:first-child {
            transform: rotate(45deg);
        }

        div:last-child {
            transform: rotate(-45deg);
        }
    }

    .collapseBtn {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 39px;
        padding: 0.5rem 0;
        color: #888;
        text-align: center;
        background-color: #f7f7f7;
        border: 0;
        cursor: pointer;

        ${max.xs`
            display: none;
        `}

        &.collapsed {
            width: 100%;

            svg {
                transform: rotate(0deg);
            }
        }

        svg {
            width: 1rem;
            height: auto;
            transform: rotate(180deg);

            path {
                fill: #888;
            }
        }

        &:hover {
            background-color: #d4d4d4;
        }
    }

    .bottomBox {
        display: flex;
        align-items: center;
        width: 100%;
        height: 2.8rem;
        color: #888;
        text-align: center;
        background-color: #f7f7f7;
        border-top: 1px solid #e2e2e2;
    }

    .version {
        display: none;
        width: 100%;

        &.show {
            display: block;
        }
    }
`;
