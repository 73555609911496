import styled from 'styled-components';
import Logo from 'components/logo/Logo';
import {
    colors,
    fontWeights,
    fontSize,
    max,
    ieOnly,
} from 'constants/constants.styled';

export default styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 1.5rem;
    overflow-y: hidden;
    margin-top: 1.5rem;

    .scrollableWrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .scrollable {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            position: absolute;
            width: 0.5rem;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: transparent;
        }

        &:hover {
            scrollbar-color: rgba(0, 0, 0, 0.1) transparent;

            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.1);
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }

    .menuBlock {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .justOnMobile {
        display: none;

        ${max.xs`
            display: block;
        `}
    }
`;

export const SmallMenuLogo = styled(Logo)`
    height: 6.5rem;
`;

export const SmallMenuItemsSC = styled.div`
    left: 7.5rem;
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 14rem;
    min-height: 4rem;
    margin-top: 0.5rem;
    overflow-y: hidden;

    a {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        color: ${colors.main};
        font-weight: ${fontWeights.light};
        font-size: ${fontSize.text};
        text-transform: uppercase;
        text-decoration: none;

        &:hover,
        &:focus,
        &.active {
            color: var(--colorSecondaryHover);
            font-weight: ${fontWeights.semiBold};
            font-weight: bold;
            text-transform: uppercase;

            &::before {
                position: absolute;
                left: 0;
                width: 0.375rem;
                height: 2rem;
                background-color: var(--colorSecondary);
                content: '';
            }
        }
    }

    .menuCategory {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        font-weight: ${fontWeights.medium};
        font-size: ${fontSize.biggerText};
        text-transform: uppercase;

        .icon {
            height: 1rem;
            margin: 0.2rem;
            margin-right: 0.5rem;
            margin-left: 0;
            fill: ${colors.main};
            stroke: ${colors.main};
        }
    }

    .menuItem {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;

        .icon {
            position: absolute;
            width: 1rem;
            height: auto;
            margin: 0;
            fill: ${colors.main};
            stroke: ${colors.main};
        }

        a {
            margin-left: 1rem;
        }
    }
`;

export const SmallMenuItemHeaderSC = styled.div`
    width: auto;
    min-width: 7.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 6rem;
    justify-content: center;

    &:hover,
    &:focus,
    &.active {
        &::before {
            position: absolute;
            left: 0;
            width: 0.375rem;
            height: 4rem;
            background-color: var(--colorSecondary);
            content: '';
        }
    }

    .smallMenuText {
        text-transform: uppercase;
        font-size: ${fontSize.smallestText};
    }

    .icon {
        height: 1.5rem;
        margin: 0.5rem;
        fill: ${colors.main};
        stroke: ${colors.main};
        ${ieOnly`
            display: block;
        `}
    }
`;
