import React, { VFC, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import SmallMenuSC, { SmallMenuLogo } from './SmallMenu.styled';
import { getActiveItem } from './helpers';
import SmallMenuItemHeader from './SmallMenuItemHeader';
import SmallMenuItems from './SmallMenuItems';
import { MenuInterface } from './Menu';
import Tooltip from 'components/layouts/Tooltip';
import { getRoute } from 'constants/routes';

interface Props {
    menuItems: MenuInterface[];
}

const SmallMenu: VFC<Props> = ({ menuItems }) => {
    const router = useRouter();
    const pseudoPathName = router.pathname + '/';
    const [activeItemHeaderId, setActiveItemHeaderId] = useState(null);

    const onMenuItemBoxVisibilityChange = (
        identifier: string,
        visible: boolean
    ) => {
        setActiveItemHeaderId(visible ? identifier : null);
    };

    return (
        <>
            <SmallMenuLogo href={getRoute('/')} />
            <SmallMenuSC>
                <div className="scrollableWrapper">
                    <div className="scrollable">
                        {menuItems.map(
                            (
                                {
                                    text,
                                    identifier,
                                    justOnMobile,
                                    icon,
                                    iconStyle,
                                    items,
                                },
                                headerKey
                            ) => {
                                const activeItem = getActiveItem(
                                    items,
                                    pseudoPathName
                                );
                                return (
                                    <div
                                        className={classNames('menuBlock', {
                                            justOnMobile,
                                        })}
                                        key={headerKey}
                                    >
                                        <Tooltip
                                            label={
                                                <SmallMenuItems
                                                    items={items}
                                                    mainItemIcon={icon}
                                                    mainItemIconStyle={
                                                        iconStyle
                                                    }
                                                    text={text}
                                                    activeItem={activeItem}
                                                />
                                            }
                                            align="left"
                                            direction="right-start"
                                            interactive={true}
                                            offset={[0, 12]}
                                            onVisibleChange={(visible) =>
                                                onMenuItemBoxVisibilityChange(
                                                    identifier,
                                                    visible
                                                )
                                            }
                                            containerStyles={{
                                                maxWidth: 'none',
                                                paddingBottom: '1.5rem',
                                            }}
                                            delayShow={50}
                                            delayHide={50}
                                        >
                                            <SmallMenuItemHeader
                                                active={
                                                    activeItemHeaderId ===
                                                        identifier ||
                                                    !!activeItem
                                                }
                                                text={text}
                                                icon={icon}
                                                iconStyle={iconStyle}
                                            />
                                        </Tooltip>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </SmallMenuSC>
        </>
    );
};

export default SmallMenu;
