import React, { CSSProperties, ReactElement } from 'react';
import classNames from 'classnames';
import { NormalMenuItemHeaderSC } from './NormalMenu.styled';
import Icon from 'components/icon/Icon';

interface Props {
    text: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    onClick: () => void;
    isOpened: boolean;
    nonClickable: boolean;
    justOnMobile?: boolean;
    iconStyle?: CSSProperties;
}

const NormalMenuItemHeader = ({
    text,
    icon,
    justOnMobile,
    onClick,
    isOpened,
    nonClickable,
    iconStyle,
}: Props): ReactElement => {
    return (
        <NormalMenuItemHeaderSC
            className={classNames({
                pointer: !nonClickable,
                nonClickable,
                justOnMobile,
            })}
            onClick={onClick}
        >
            <div className="flex" title={text}>
                <span className="left">
                    {icon && (
                        <Icon icon={icon} style={iconStyle} className="icon" />
                    )}
                    {text}
                </span>
                <div className="right">
                    {!nonClickable && (
                        <span
                            className={classNames('toggleIcon', {
                                open: isOpened,
                            })}
                        />
                    )}
                </div>
            </div>
        </NormalMenuItemHeaderSC>
    );
};

export default NormalMenuItemHeader;
